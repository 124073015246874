import React from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { Storage } from "aws-amplify";
import clsx from "clsx";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  TextareaAutosize,
  TextField,
  List,
  Fab,
} from "@material-ui/core";
import { useUser } from "../UserContext";
import UserSchema from "./user.schema";
import { useEffect } from "react";
import { useStyles } from "@config";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { useLayout } from "@components/Layout";
import AvatarImage from "./AvatarImage";
import DocumentListImage from "./DocumentListImage";

const UserForm = ({ match }) => {
  const [dataLayout] = useLayout();
  const {
    isLoading,
    setIsLoading,
    fetchUser,
    insertUser,
    updateUser,
    setAvatarImage,
    avatarImage,
    cleanRoomForm,
    documentImages,
    user,
  } = useUser();
  let history = useHistory();
  const {
    errors,
    setValues,
    values: formValues,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: user,
    validationSchema: UserSchema,
    onSubmit,
  });
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  async function onSubmit(values) {
    setIsLoading(true);
    if (match.params.id === "new") {
      await insertUser(
        {
          ...values,
          id: null,
        },
        avatarImage,
        documentImages,
      );
    } else {
      await updateUser(values, avatarImage, documentImages);
    }
    setIsLoading(false);
    setTimeout(() => {
      cleanRoomForm();
      history.push("/users");
    }, 500);
  }

  useEffect(() => {
    (async () => {
      if (match.params.id !== "new") {
        setIsLoading(true);
        const user = await fetchUser(match.params.id);
        
        if (user.avatar) {
          const image = await Storage.get(user.avatar.key);

          setAvatarImage({
            file: null,
            url: image,
          });
        }

        setValues({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          address1: user.address1 || '',
          address2: user.address2 || '',
          city: user.city || '',
          state: user.state || '',
          email: user.email,
          phone: user.phone,
          postalCode: user.postalCode || '',
          terms: user.terms,
          username: user.username || '',
        });
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  function onCancel() {
    cleanRoomForm();
    history.push("/users");
  }

  return (
    <Grid container spacing={1}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} style={{ marginBottom: "1em" }}>
            <Grid item xs={12}>
              <List
                className={clsx(
                  dataLayout.openDrawer && classes.drawerPaperFormActionOpen,
                  !dataLayout.openDrawer && classes.drawerPaperFormActionClose
                )}
              >
                <Fab
                  size="small"
                  color="primary"
                  aria-label="save"
                  type="submit"
                >
                  <SaveIcon />
                </Fab>

                <Fab
                  size="small"
                  aria-label="cancel"
                  style={{ marginTop: "0.5em" }}
                  onClick={onCancel}
                >
                  <CloseIcon />
                </Fab>
              </List>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper className={fixedHeightPaper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.firstName !== undefined}
                        name="firstName"
                        label="First Name"
                        value={formValues.firstName}
                        helperText={errors.firstName ? errors.firstName : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.lastName !== undefined}
                        name="lastName"
                        label="Last Name"
                        value={formValues.lastName}
                        helperText={errors.lastName ? errors.lastName : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.city !== undefined}
                        name="city"
                        label="City"
                        value={formValues.city}
                        helperText={errors.city ? errors.city : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.state !== undefined}
                        name="state"
                        label="State"
                        value={formValues.state}
                        helperText={errors.state ? errors.state : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.postalCode !== undefined}
                        name="postalCode"
                        label="Postal Code"
                        value={formValues.postalCode}
                        helperText={errors.postalCode ? errors.postalCode : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.phone !== undefined}
                        name="phone"
                        label="Phone"
                        value={formValues.phone}
                        helperText={errors.phone ? errors.phone : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={12} md={5}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.email !== undefined}
                        name="email"
                        label="Email"
                        value={formValues.email}
                        helperText={errors.email ? errors.email : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.username !== undefined}
                        name="username"
                        label="Username"
                        value={formValues.username}
                        helperText={errors.username ? errors.username : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>


                  <Grid item xs={12}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.address1 !== undefined}
                        name="address1"
                        label="Address1"
                        value={formValues.address1}
                        helperText={errors.address1 ? errors.address1 : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>


                  <Grid item xs={12}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.address2 !== undefined}
                        name="address2"
                        label="Address2"
                        value={formValues.address2}
                        helperText={errors.email ? errors.address2 : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12}>
                    <section>
                      <TextareaAutosize
                        className={[classes.textField].join(" ")}
                        name="terms"
                        rowsMin={12}
                        value={formValues.terms}
                        onChange={handleChange}
                        placeholder="Enter terms"
                      />
                    </section>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} className={classes.mt1}>
              <Paper className={fixedHeightPaper}>
                <h2>{`Images`}</h2>

                <section>
                  <AvatarImage />
                </section>

                <section className={classes.mt5}>
                  <DocumentListImage />
                </section>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default UserForm;
