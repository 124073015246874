// Import Swiper React components
import { useStyles, useImageFile } from "@config";
import { Button, Divider, Fab, TextField } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Pagination } from "swiper";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useRoom } from "modules/Room/RoomContext";
import { useState } from "react";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "@config/swiper-style.css";

SwiperCore.use([Navigation, Pagination, Thumbs]);

const RoomListImage = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState();
  const classes = useStyles();
  const [, setImageFile] = useImageFile();
  const { roomImages, setRoomImages } = useRoom();

  function onAddNewImage(e) {
    const allowed = ["image/jpeg", "image/png", "image/jpg"];
    if (e.target.files[0]) {
      if (allowed.includes(e.target.files[0].type)) {
        const image = new Image();
        setImageFile(e.target.files[0], image);

        image.onload = () => {
          setRoomImages([
            ...roomImages,
            {
              file: e.target.files[0],
              url: image.src,
              bucket: "",
              description: "",
              key: "",
              region: "",
              action: "ADD",
            },
          ]);
        };
      }
    }
  }

  function onDeleteImage(image, index) {
    const _propertyImages =
      image.action === "ADD"
        ? roomImages.filter((_, idx) => index !== idx)
        : roomImages.map((propertyImage, idx) => {
            if (idx === index) {
              return {
                ...propertyImage,
                action: "DELETE",
              };
            }

            return propertyImage;
          });

    setRoomImages(_propertyImages);
  }

  function onChangeInput(e, idx) {
    const _propertyImages = roomImages.map((propertyImage, index) => {
      if (index === idx) {
        return {
          ...propertyImage,
          description: e.target.value,
        };
      }

      return propertyImage;
    });

    setRoomImages(_propertyImages);
  }

  return (
    <section>
      <div>
        <h2>
          {"Room Images"}
          <Divider className={classes["mt0.5"]} />
        </h2>
      </div>

      <div style={{ width: "100%" }}>
        <input
          accept={"image/jpeg, image/jpg, image/png"}
          style={{ display: "none", width: "100%", height: "50px" }}
          id="button-file"
          type="file"
          onChange={onAddNewImage}
        />
        <label htmlFor="button-file">
          <Button
            style={{ width: "100%", height: "50px" }}
            type={"button"}
            variant="contained"
            color="primary"
            disabled={false}
            component="span"
          >
            {"Add An Image"}
          </Button>
        </label>
      </div>

      <section className={classes["mt2"]}>
        {thumbsSwiper && (
          <Swiper
            observer
            spaceBetween={50}
            slidesPerView={1}
            thumbs={{ swiper: thumbsSwiper }}
            pagination={{ clickable: true }}
            navigation
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => {}}
            onSlideChange={() => {
              // console.log("slide change")
            }}
          >
            {roomImages &&
              roomImages.map(
                (roomImage, index) =>
                  roomImage.action !== "DELETE" && (
                    <SwiperSlide key={index.toString()}>
                      <section>
                        <section className={classes.mt1}>
                          <TextField
                            className={[classes.textField].join(" ")}
                            name="shortDescription"
                            label="Short Description"
                            value={roomImage.description}
                            variant="outlined"
                            onChange={(e) => onChangeInput(e, index)}
                          />
                        </section>

                        <section className={classes.mt1}>
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                position: "absolute",
                                top: "3px",
                                left: "3px",
                              }}
                            >
                              <Fab color="secondary" aria-label="edit">
                                <DeleteOutlineIcon
                                  fontSize={"large"}
                                  onClick={() =>
                                    onDeleteImage(roomImage, index)
                                  }
                                />
                              </Fab>
                            </div>
                          </div>

                          <img
                            width="100%"
                            height={640}
                            src={roomImage.url}
                            alt={""}
                          />
                        </section>
                      </section>
                    </SwiperSlide>
                  )
              )}
          </Swiper>
        )}

        <Swiper
          onSwiper={(swiper) => setThumbsSwiper(swiper)}
          observer
          watchSlidesVisibility
          watchSlidesProgress
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
        >
          {roomImages &&
            roomImages.map(
              (roomImage, index) =>
                roomImage.action !== "DELETE" && (
                  <SwiperSlide key={index.toString()}>
                    <img
                      width={"100%"}
                      height={200}
                      src={roomImage.url}
                      alt={"Al1"}
                    />
                  </SwiperSlide>
                )
            )}
        </Swiper>
      </section>
    </section>
  );
};

export default RoomListImage;
