export const property = {
  id: '',
  baths: 0,
  beds: 0,
  title: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  description: '',
  shortDescription: '',
  heroImage: {
    bucket: '',
    description: '',
    key: '',
    region: '',
  },
  images: [],
};

export const initialState = {
  property,
  properties: [],
  isLoading: false,
  rooms: [],
  coverImage: '',
  coverImageBlob: null,
  propertyImages: [],
}

export function propertyReducer(state, action) {
  switch (action.type) {
    case 'SET_PROPERTY': {
      return {
        ...state,
        property: action.property,
      }
    }

    case 'SET_PROPERTIES': {
      return {
        ...state,
        properties: action.properties,
      }
    }

    case 'SET_IS_LOADING': {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case 'SET_ROOMS_BY_PROPERTY': {
      return {
        ...state,
        rooms: action.rooms,
      }
    }

    case 'SET_COVER_IMAGE': {
      return {
        ...state,
        coverImage: action.coverImage,
      }
    }

    case 'SET_COVER_IMAGE_BLOB': {
      return {
        ...state,
        coverImageBlob: action.coverImageBlob,
      }
    }

    case 'SET_PROPERTY_IMAGES': {
      return {
        ...state,
        propertyImages: action.propertyImages,
      }
    }

    case 'CLEAN_ROOM_FORM': {
      return {
        ...state,
        property,
        coverImage: '',
        coverImageBlob: null,
        propertyImages: [],
      }
    }

    default:
      return state
  }
}
