export const user = {
  id: '',
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  email: '',
  phone: '',
  terms: '',
  documents: [],
  avatar: {
    description: '',
    bucket: '',
    region: '',
    key: '',
  },
  username: '',
  createdAt:''
};

export const initialState = {
  user,
  users: [],
  isLoading: false,
  rooms: [],
  avatarImage: {
    file: null,
    url: ''
  },
  documentImages: []
}

export function userReducer(state, action) {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        user: action.user,
      }
    }

    case 'SET_USERS': {
      return {
        ...state,
        users: action.users,
      }
    }

    case 'SET_IS_LOADING': {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case 'SET_AVATAR_IMAGE' : {
      return {
        ...state,
        avatarImage: action.avatarImage,
      }
    }

    case 'SET_DOCUMENT_IMAGES': {
      return {
        ...state,
        documentImages: action.documentImages,
      }
    }

    case 'CLEAN_ROOM_FORM': {
      return {
        ...state,
        user,
        avatarImage: {
          file: null,
          url: ''
        },
        documentImages: [],
      }
    }

    default:
      return state
  }
}
