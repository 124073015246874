export const reservation = {};

export const initialState = {
  reservation,
  reservations: [],
  isLoading: false,
  reservationId: '',
}

export function reservationReducer(state, action) {
  switch (action.type) {
    case 'SET_RESERVATION_ID': {
      return {
        ...state,
        reservationId: action.reservationId,
      }
    }

    case 'SET_RESERVATION': {
      return {
        ...state,
        reservation: action.reservation,
      }
    }

    case 'SET_RESERVATIONS': {
      return {
        ...state,
        reservations: action.reservations,
      }
    }

    case 'SET_IS_LOADING': {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case 'CLEAN_FORM': {
      return {
        ...state,
        reservation,
      }
    }

    default:
      return state
  }
}
