import React, { useMemo } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { Storage } from "aws-amplify";
import clsx from "clsx";
import {
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextareaAutosize,
  TextField,
  Switch,
  List,
  Fab,
} from "@material-ui/core";
import { useRoom } from "../RoomContext";
import { price } from "../RoomContext/context.store";
import RoomSchema from "./room.schema";
import { useEffect } from "react";
import { useStyles } from "@config";
import RoomHeroImages from "./RoomHeroImages";
import RoomListImage from "./RoomListImage";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { Select, SelectAutoComplete } from "@components";
import { useState } from "react";
import { useLayout } from "@components/Layout";
import RoomPriceForm from "../RoomPriceForm";
import AddIcon from "@material-ui/icons/Add";
import { AmenityArrayForm } from "modules/Amenity";
import { useAmenity } from "modules/Amenity/AmenityContext";
import { constructImageUrl } from "@components/Images";

const RoomForm = ({ match }) => {
  const { amenity } = useAmenity();
  const [dataLayout] = useLayout();
  const {
    isLoading,
    setIsLoading,
    fetchRoom,
    insertRoom,
    updateRoom,
    setCoverImage,
    coverImage,
    roomImages,
    cleanRoomForm,
    room,
    fetchProperties,
    properties,
  } = useRoom();
  let history = useHistory();
  const {
    errors,
    setValues,
    setFieldValue,
    values: formValues,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: room,
    validationSchema: RoomSchema,
    onSubmit,
  });
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [propertySelected, setPropertySelected] = useState(null);
  const [propertyName, setPropertyName] = useState(null);

  async function onSubmit(values) {
    setIsLoading(true);
    if (match.params.id === "new") {
      await insertRoom(
        {
          ...values,
          id: null,
        },
        coverImage,
        roomImages
      );
    } else {
      await updateRoom(values, coverImage, roomImages);
    }
    setIsLoading(false);
    setTimeout(() => {
      cleanRoomForm();
      history.push("/rooms");
    }, 500);
  }

  useEffect(() => {
    (async () => {
      await fetchProperties();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (match.params.id !== "new" && properties) {
        setIsLoading(true);
        const room = await fetchRoom(match.params.id);

        if (room.heroImage) {
          // const image = await Storage.get(room.heroImage.key);
          const image = constructImageUrl(room.heroImage);
          setCoverImage({
            file: null,
            url: image,
          });
        }

        const prices = room.prices.map((price) => {
          return {
            ...price,
            displayAmount: price.displayAmount
              ? parseInt(price.displayAmount, 10)
              : 0,
            amount: price.amount ? parseInt(price.amount, 10) : 0,
            increment: price.increment ? parseInt(price.increment, 10) : 0,
          };
        });

        const propertySelected = properties.find(
          (property) => property.id === room.Property.id
        );
        if (propertySelected) {
          setPropertySelected(propertySelected);
        }

        setValues({
          id: room.id,
          title: room.title,
          discount: room.discount,
          price: room.price,
          hasBathroom: room.hasBathroom,
          heroImage: room.heroImage,
          description: room.description || "",
          roomPropertyId: room.Property.id,
          prices,
          amenities: room.Amenities.items,
          bedType: room.bedType || "",
          sqft: room.sqft || 0,
          active: room.active,
        });
        setPropertyName(room.Property.title);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, properties]);

  function onCancel() {
    cleanRoomForm();
    history.push("/rooms");
  }

  function handleSwitchChange(e) {
    setFieldValue(e.target.name, e.target.checked, true);
  }

  function onChangeAutoComplete(index, data) {
    if (data) {
      setPropertySelected(data);
      setFieldValue("roomPropertyId", data.id);
    } else {
      setFieldValue("roomPropertyId", null);
    }
  }

  function handlePriceChange(e, index) {
    console.log(index);
    const {
      target: { name, value },
    } = e;

    formValues.prices[index] = {
      ...formValues.prices[index],
      [name]: value,
    };

    setValues({
      ...formValues,
    });
  }

  function onNewPrice() {
    const prices = [price].concat(formValues.prices);

    setValues({
      ...formValues,
      prices,
    });
  }

  function onDeletePrice(priceId) {
    const prices = formValues.prices.filter((price) => price.id !== priceId);
    setValues({
      ...formValues,
      prices,
    });
  }

  function onNewAmenities() {
    const amenities = [amenity].concat(formValues.amenities);

    setValues({
      ...formValues,
      amenities,
    });
  }

  function handleAmenityChange(e, index) {
    const {
      target: { name, value },
    } = e;

    formValues.amenities[index] = {
      ...formValues.amenities[index],
      [name]: value,
    };

    setValues({
      ...formValues,
    });
  }

  function onDeleteAmenity(index) {
    const amenities = formValues.amenities.map((amenity, idx) => {
      if (index === idx) {
        return {
          ...amenity,
          delete: true,
        };
      }

      return amenity;
    });

    setValues({
      ...formValues,
      amenities,
    });
  }

  const amenitiesMemo = useMemo(
    () => formValues.amenities,
    [formValues.amenities]
  );

  return (
    <Grid container spacing={1}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} style={{ marginBottom: "1em" }}>
            <Grid item xs={12}>
              <List
                className={clsx(
                  dataLayout.openDrawer && classes.drawerPaperFormActionOpen,
                  !dataLayout.openDrawer && classes.drawerPaperFormActionClose
                )}
              >
                <Fab
                  size="small"
                  color="primary"
                  aria-label="save"
                  type="submit"
                >
                  <SaveIcon />
                </Fab>

                <Fab
                  size="small"
                  aria-label="cancel"
                  style={{ marginTop: "0.5em" }}
                  onClick={onCancel}
                >
                  <CloseIcon />
                </Fab>
              </List>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper className={fixedHeightPaper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.title !== undefined}
                        name="title"
                        label="Title"
                        value={formValues.title}
                        helperText={errors.title ? errors.title : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.discount !== undefined}
                        name="discount"
                        type={"number"}
                        label="Discount"
                        value={formValues.discount}
                        InputProps={{ inputProps: { min: 0 } }}
                        helperText={errors.discount ? errors.discount : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.price !== undefined}
                        name="price"
                        type={"number"}
                        label="Price"
                        value={formValues.price}
                        InputProps={{ inputProps: { min: 0 } }}
                        helperText={errors.price ? errors.price : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <section>
                      <Select
                        name="bedType"
                        label={"Bed"}
                        value={formValues.bedType}
                        error={errors.bedType !== undefined}
                        helperText={errors.bedType ? errors.bedType : ""}
                        values={[
                          {
                            id: "Twin",
                            name: "Twin",
                          },
                          {
                            id: "Full",
                            name: "Full",
                          },
                          {
                            id: "Queen",
                            name: "Queen",
                          },
                          {
                            id: "King",
                            name: "King",
                          },
                        ]}
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.sqft !== undefined}
                        name="sqft"
                        type={"number"}
                        label="SQFT"
                        value={formValues.sqft}
                        InputProps={{ inputProps: { min: 0 } }}
                        helperText={errors.sqft ? errors.sqft : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <section>
                      <FormControlLabel
                        className={""}
                        control={
                          <Switch
                            name="active"
                            checked={formValues.active}
                            onChange={handleSwitchChange}
                          />
                        }
                        label="Active"
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <section>
                      <FormControlLabel
                        className={""}
                        control={
                          <Switch
                            name="hasBathroom"
                            checked={formValues.hasBathroom}
                            onChange={handleSwitchChange}
                          />
                        }
                        label="Has Bathroom"
                      />
                    </section>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <section>
                      <SelectAutoComplete
                        classes={[classes.textField].join(" ")}
                        errors={errors}
                        label={"Property"}
                        name={"roomPropertyId"}
                        items={properties}
                        value={propertySelected}
                        loading={false}
                        bindDataToValue="title"
                        onChange={onChangeAutoComplete}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12}>
                    <section>
                      <TextareaAutosize
                        className={[classes.textField].join(" ")}
                        name="description"
                        rowsMin={12}
                        value={formValues.description}
                        onChange={handleChange}
                        placeholder="Enter Description"
                      />
                    </section>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {match.params.id !== "new" && (
              <Grid item xs={12} className={classes.mt1}>
                <Paper className={fixedHeightPaper}>
                  <section
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <h2>{`Amenities`}</h2>
                      <div
                        className={clsx(classes["ml1"], classes["mt0.5"])}
                        onClick={() => onNewAmenities()}
                      >
                        <Fab
                          size="small"
                          color="default"
                          aria-label="add-amenity"
                          type="button"
                        >
                          <AddIcon />
                        </Fab>
                      </div>
                    </div>
                  </section>

                  <section>
                    <AmenityArrayForm
                      key={amenity.id}
                      amenitiesValues={amenitiesMemo}
                      errors={errors}
                      handleChange={handleAmenityChange}
                      onDeleteAmenity={onDeleteAmenity}
                    />
                  </section>
                </Paper>
              </Grid>
            )}

            {match.params.id !== "new" && (
              <Grid item xs={12} className={classes.mt1}>
                <Paper className={fixedHeightPaper}>
                  <section
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <h2>{`Prices`}</h2>
                      <div
                        className={clsx(classes["ml1"], classes["mt0.5"])}
                        onClick={() => onNewPrice()}
                      >
                        <Fab
                          size="small"
                          color="default"
                          aria-label="add-price"
                          type="button"
                        >
                          <AddIcon />
                        </Fab>
                      </div>
                    </div>

                    {/* {errors.prices && !isEmpty(errors.prices) && (
                      <h3 style={{ color: "red" }}>
                        {`Price forms need to be verified`}
                      </h3>
                    )} */}
                  </section>

                  <section>
                   <RoomPriceForm
                      formValues={formValues}
                      errors={errors}
                      handleChange={handlePriceChange}
                      onDeletePrice={onDeletePrice}
                      propertyName={propertyName}
                    />
                  </section>
                </Paper>
              </Grid>
            )}

            <Grid item xs={12} className={classes.mt1}>
              <Paper className={fixedHeightPaper}>
                <h2>{`Images`}</h2>

                <section>
                  <RoomHeroImages />
                </section>

                <section className={classes.mt5}>
                  <RoomListImage />
                </section>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default RoomForm;
