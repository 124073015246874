// Import Swiper React components
import { useStyles, useImageFile } from "@config";
import { Button, Divider, Fab, TextField } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import { useUser } from "modules/User/UserContext";

const DocumentListImage = () => {
  const classes = useStyles();
  const [, setImageFile] = useImageFile();
  const { documentImages, setDocumentImages } = useUser();

  function onAddNewImage(e) {
    const allowed = ["image/jpeg", "image/png", "image/jpg"];
    if (e.target.files[0]) {
      if (allowed.includes(e.target.files[0].type)) {
        const image = new Image();
        setImageFile(e.target.files[0], image);

        image.onload = () => {
          setDocumentImages([
            ...documentImages,
            {
              file: e.target.files[0],
              url: image.src,
              bucket: "",
              description: "",
              key: "",
              region: "",
              action: "ADD",
            },
          ]);
        };
      }
    }
  }

  function onDeleteImage(image, index) {
    const _propertyImages =
      image.action === "ADD"
        ? documentImages.filter((_, idx) => index !== idx)
        : documentImages.map((propertyImage, idx) => {
            if (idx === index) {
              return {
                ...propertyImage,
                action: "DELETE",
              };
            }

            return propertyImage;
          });

    setDocumentImages(_propertyImages);
  }

  function onChangeInput(e, idx) {
    const _propertyImages = documentImages.map((propertyImage, index) => {
      if (index === idx) {
        return {
          ...propertyImage,
          description: e.target.value,
        };
      }

      return propertyImage;
    });

    setDocumentImages(_propertyImages);
  }

  return (
    <section>
      <div>
        <h2>
          {"Documents"}
          <Divider className={classes["mt0.5"]} />
        </h2>
      </div>

      <div style={{ width: "100%" }}>
        <input
          accept={"image/jpeg, image/jpg, image/png"}
          style={{ display: "none", width: "100%", height: "50px" }}
          id="button-file"
          type="file"
          onChange={onAddNewImage}
        />
        <label htmlFor="button-file">
          <Button
            style={{ width: "100%", height: "50px" }}
            type={"button"}
            variant="contained"
            color="primary"
            disabled={false}
            component="span"
          >
            {"Add An Image"}
          </Button>
        </label>
      </div>

      <section className={classes["mt2"]}>
        <Swiper
          observer
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => {}}
          onSlideChange={() => {
            // console.log("slide change")
          }}
        >
          {documentImages &&
            documentImages.map(
              (documentImage, index) =>
              documentImage.action !== "DELETE" && (
                  <SwiperSlide key={index.toString()}>
                    <section>
                      <section className={classes.mt1}>
                        <TextField
                          className={[classes.textField].join(" ")}
                          name="shortDescription"
                          label="Short Description"
                          value={documentImage.description}
                          variant="outlined"
                          onChange={(e) => onChangeInput(e, index)}
                        />
                      </section>

                      <section className={classes.mt1}>
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              position: "absolute",
                              top: "3px",
                              left: "3px",
                            }}
                          >
                            <Fab color="secondary" aria-label="edit">
                              <DeleteOutlineIcon
                                fontSize={"large"}
                                onClick={() =>
                                  onDeleteImage(documentImage, index)
                                }
                              />
                            </Fab>
                          </div>
                        </div>

                        <img
                          width="100%"
                          height={640}
                          src={documentImage.url}
                          alt={""}
                        />
                      </section>
                    </section>
                  </SwiperSlide>
                )
            )}
        </Swiper>
      </section>
    </section>
  );
};

export default DocumentListImage;
