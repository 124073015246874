const sortByText = (a, b) => {
  var nameA = a.title.toUpperCase(); // ignore upper and lowercase
  var nameB = b.title.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export default class PropertyListService {
  parseList(rawDataProperties) {
    const properties = rawDataProperties
      .map(
        ({
          id,
          title,
          postalCode,
          state,
          shortDescription,
          city,
          baths,
          beds,
        }) => ({
          id,
          title,
          postalCode,
          state,
          shortDescription,
          city,
          baths: Number.parseInt(baths, 10),
          beds: Number.parseInt(beds, 10),
        })
      )
      .sort(sortByText);

    return properties;
  }
}
