import * as Yup from "yup";

const AmenitySchema = Yup.object().shape({
  sortOrder: Yup.number().required("Required"),
  tooltip: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  icon: Yup.string().required("Required"),
  grouping: Yup.string().required("Required"),
});

export default AmenitySchema;
