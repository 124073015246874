import React, { useEffect } from "react";
// import clsx from "clsx";
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import { Table } from "@components";
import { useHistory } from "react-router-dom";
import UserListService from "./UserListService";
import { useMemo } from "react";
import { useUser } from "../UserContext";
import { sortString, sortDate, useStyles } from "@config";

const UserList = () => {
  const classes = useStyles();
  const columns = useMemo(
    () => [
      {
        Header: "User List",
        columns: [
          {
            Header: `id`,
            accessor: "id",
          },
          {
            Header: `Sign Up Date`,
            accessor: "createdAt",
            Cell: ({ value }) => new Date(value).toLocaleDateString("en-US"),
            sortType: (a, b) => {
              return sortDate(a.original.createdAt, b.original.createdAt);
            },
          },
          {
            Header: `First Name`,
            accessor: "firstName",
            sortType: (a, b) => {
              return sortString(a.original.firstName, b.original.firstName);
            },
          },
          {
            Header: `Last Name`,
            accessor: "lastName",
            sortType: (a, b) => {
              return sortString(a.original.lastName, b.original.lastName);
            },
          },
          {
            Header: `Phone`,
            accessor: "phone",
          },
          {
            Header: "Email",
            accessor: "email",
            sortType: (a, b) => {
              return sortString(a.original.email, b.original.email);
            },
          },
          {
            Header: "Username",
            accessor: "username",
            Cell: ({ value }) => <div>{`${value ? value : "N/A"}`}</div>,
            sortType: (a, b) => {
              return sortString(a.original.username, b.original.username);
            },
          },
        ],
      },
    ],
    []
  );
  const { users, setIsLoading, setUsers, fetchUsers, isLoading } = useUser();
  let history = useHistory();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const userListService = new UserListService();
      const rawData = await fetchUsers();

      const _users = userListService.parseList(rawData);
      setUsers(_users);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function goTo(values) {
    history.push(`/users/${values.id}`);
  }

  const data = React.useMemo(() => users, [users]);

  return (
    <Grid container spacing={1}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={data.sort((a,b)=> new Date(b.createdAt) - new Date(a.createdAt))}
          onRowClick={(values) => goTo(values)}
          onAddRow={() => {
            goTo({ id: "new" });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UserList;
