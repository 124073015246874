export const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'black',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  color: 'white',
  outline: 'none',
  verticalAlign: 'middle',
  width: '100%',
  height: '450px',
  // minWidth: '371px',
  WebkitBorderRadius: '3px',
  MozBorderRadius: '3px',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'border .24s ease-in-out, box-shadow .24s ease-in-out, outline .24s ease-in-out',
};

export const activeStyle = {
  borderColor: '#2196f3'
};

export const acceptStyle = {
  borderColor: 'green',
  outline: '1px solid green',
  boxShadow: 'inset 0 0 10px green'
};

export const rejectStyle = {
  borderColor: '#ff1744',
  outline: '1px solid #ff1744',
  boxShadow: 'inset 0 0 10px #ff1744'
};
