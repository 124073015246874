import { DropzoneImageInput } from '@components';
import { useProperty } from '../../PropertyContext';
import React from 'react'
import { Divider } from '@material-ui/core';
import { useStyles, useImageFile } from '@config';

const PropertyHeroImages = () => {
  const classes = useStyles()
  const {
    coverImage,
    setCoverImage,
    setCoverImageBLOB
  } = useProperty()
  const [, setImageFile] = useImageFile()

  function onDrop(acceptedFiles, fileRejections, event) {
    if (acceptedFiles[0]) {
      const image = new Image();
      setImageFile(acceptedFiles[0], image)
      setCoverImageBLOB(acceptedFiles[0])

      image.onload = () => {
        setCoverImage(image.src)
      }
    }
  }

  return (
    <>
      <section>
        <div>
          <h2>
            {'Cover'}
            <Divider className={classes['mt0.5']} />
          </h2>
        </div>
        <DropzoneImageInput
          onDrop={onDrop}
          imageURL={coverImage}
        />
      </section>
    </>
  )
}

export default PropertyHeroImages;

