export async function copyTextToClipboard(text) {
    await navigator.clipboard.writeText(text);
    alert("Text Copied!");
    return;
    
    // if ('clipboard' in navigator) {
    //   return await navigator.clipboard.writeText(text);
    // } else {
    //   return document.execCommand('copy', true, text);
    // }
  }