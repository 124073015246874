import { putFile } from "@config";
import {
  createContext,
  useReducer,
  useMemo,
  useContext,
  useEffect,
} from "react";
import { initialState, reservationReducer } from "./context.store";
import ReservationService from "./ReservationService";
import { useLayout } from "@components/Layout";

export const ReservationContext = createContext(initialState);

ReservationContext.displayName = "ReservationContext";

export const ReservationProvider = (props) => {
  const [state, dispatch] = useReducer(reservationReducer, initialState);

  const setReservationId = (reservationId) =>
    dispatch({ type: "SET_RESERVATION_ID", reservationId });

  const setReservation = (reservation) =>
    dispatch({ type: "SET_RESERVATION", reservation });

  const setReservations = (reservations) =>
    dispatch({ type: "SET_RESERVATIONS", reservations });

  const setIsLoading = (isLoading) =>
    dispatch({ type: "SET_IS_LOADING", isLoading });

  const cleanForm = () => dispatch({ type: "CLEAN_FORM" });

  const fetchReservations = async (token) => {
    const reservationService = new ReservationService();

    try {
      
      const data = await reservationService.getList(token);
      
      return data;
    } catch (err) {}
  };

  const fetchReservation = async (reservationId) => {
    const reservationService = new ReservationService();

    try {
      const data = await reservationService.getReservation(reservationId);
      return data;
    } catch (err) {}
  };

  const fetchAvailableDatesByRoom = async (roomId) => {
    const reservationService = new ReservationService();

    try {
      const data = await reservationService.getReservationsByRoom(roomId);
      return data;
    } catch (err) {}
  };

  const fetchPaymentSchedule = async (options) => {
    const reservationService = new ReservationService();

    try {
      const data = await reservationService.getPaymentSchedule(options);
      return data;
    } catch (err) {}
  };

  const fetchDocumentUsers = async (userId) => {
    const reservationService = new ReservationService();

    try {
      const data = await reservationService.getDocumentUser(userId);
      return data;
    } catch (err) {}
  };

  // const insertReservation = async (reservation) => {};

  // const updateReservation = async (reservation) => {};

  const value = useMemo(
    () => ({
      ...state,
      setReservation,
      setReservations,
      setIsLoading,
      fetchReservations,
      fetchReservation,
      fetchAvailableDatesByRoom,
      fetchPaymentSchedule,
      cleanForm,
      fetchDocumentUsers,
      setReservationId,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <ReservationContext.Provider value={value} {...props} />;
};

export const useReservation = () => {
  const context = useContext(ReservationContext);
  if (context === undefined) {
    throw new Error(`useReservation must be used within a ReservationProvider`);
  }

  return context;
};

export const ManagedReservationContext = ({ children }) => {
  const [, setDataLayout] = useLayout();

  useEffect(() => {
    setDataLayout((datalayout) => ({
      ...datalayout,
      drawerTitle: "Reservations",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ReservationProvider>{children}</ReservationProvider>;
};
