import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControl, CircularProgress } from '@material-ui/core';
import { isEmpty } from '@config';

function SelectAutoComplete({
  classes,
  name,
  items,
  bindDataToValue,
  label,
  value,
  errors,
  disabled = false,
  loading = true,
  onChange,
  styles = {}
}) {
  return (
    <FormControl
      style={styles}
      className={classes}
      variant="outlined"
    >
      <Autocomplete
        id={name}
        options={items}
        disabled={disabled}
        onChange={onChange}
        loading={loading}
        value={value}
        getOptionLabel={option => {
          if (isEmpty(option)) {
            return '';
          }

          return option[bindDataToValue];
        }}
        getOptionSelected={(option, value) => {
          if (isEmpty(option) || isEmpty(value)) {
            return '{ id: "" }';
          }

          return option.id === value.id;
        }}
        renderInput={(params) => (
          <TextField
            error={errors[name] !== undefined}
            name={name}
            {...params}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </FormControl>
  )
}

export default SelectAutoComplete;
