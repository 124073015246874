import React from "react";
import { useStyles } from "@config";
import clsx from "clsx";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as SelectMUI,
} from "@material-ui/core";

const Select = ({ name, label, onChange, value, values, error = false, helperText, disabled }) => {
  const classes = useStyles();

  return (
    <>
      <FormControl variant="outlined" className={clsx(classes.selectField)} error>
        <InputLabel error={error}>{label}</InputLabel>
        <SelectMUI
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          label="Age"
          error={error && error}
          disabled={disabled}
        >
          {values.map(value => (
            <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
          ))}
        </SelectMUI>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default Select;
