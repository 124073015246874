import { BrowserRouter as Router } from "react-router-dom";
import { PartialView, SideMenu } from "@components";
import { LayoutProvider } from "@components/Layout";
import { AppRouter } from "@components/PartialView";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Amplify from "@aws-amplify/core";
import awsconfig from "./aws-exports";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import "./index.css";
Amplify.configure(awsconfig);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2f855a",
    },
    secondary: {
      main: "#2B873A",
    },
  },
});

export async function KickUnauthorizedUsers(state, data) {
  if (state !== "signedin" || data === undefined || data === null) {
    return;
  }

  let groups = data.signInUserSession.accessToken.payload["cognito:groups"];
  if (groups) {
    if (!groups.includes("Admin")) {
      alert("You do not have access.")
      await Auth.signOut();
    }
  } else {
    alert("You do not have access.")
    await Auth.signOut();
  }
}

function App() {
  return (
    <div className="App">
      <AmplifyAuthenticator handleAuthStateChange={KickUnauthorizedUsers}>
        <AmplifySignIn slot="sign-in" hideSignUp federated={{}} />
        <ThemeProvider theme={theme}>
          <LayoutProvider>
            <Router>
              <PartialView>
                <SideMenu>
                  <AppRouter />
                </SideMenu>
              </PartialView>
            </Router>
          </LayoutProvider>
        </ThemeProvider>
      </AmplifyAuthenticator>
    </div>
  );
}

export default App;
