import { createAmenity, updateAmenity, deleteAmenity } from '@data/mutations'
import { API, graphqlOperation } from 'aws-amplify';

export default class AmenityService {
  async insertAmenity(amenity) {
    try {
      const {
        data: {
          createAmenity: data
        }
      } = await API.graphql(graphqlOperation(createAmenity, { input: amenity }));
      
      return data;
    } catch (err) {
      throw err;
    }
  }

  async updateAmenity(amenity) {
    try {
      const {
        data: {
          updateAmenity: data
        }
      } = await API.graphql(graphqlOperation(updateAmenity, { input: amenity }));
      
      return data;
    } catch (err) {
      throw err;
    }
  }

  async deleteAmenity(id) {
    try {
      const {
        data: {
          deleteAmenity: data
        }
      } = await API.graphql(graphqlOperation(deleteAmenity, { input: { id } }));
      
      return data;
    } catch (err) {
      throw err;
    }
  }
}