import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Chart from "./Chart/Chart";
import MyDashboard from "./AnalyticsDashboard";
import { useStyles } from "@components/SideMenu/styles";
import { Grid, Paper } from "@material-ui/core";
import { useLayout } from "@components/Layout";

const Dashboard = () => {
  const classes = useStyles();
  const [, setDataLayout] = useLayout();

  useEffect(() => {
    setDataLayout((datalayout) => ({
      ...datalayout,
      drawerTitle: "Dashboard",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fixedHeightPaper = clsx(classes.paper);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={fixedHeightPaper}>
          <MyDashboard />
        </Paper>
      </Grid>

      {/* Chart 
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightPaper}>
          <Chart />
        </Paper>
      </Grid>
*/}
      {/* Recent Deposits 
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightPaper}>
          <Deposits />
        </Paper>
      </Grid>
*/}
      {/* Recent Orders 
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Orders />
        </Paper>
      </Grid>
      */}
    </Grid>
  );
};

export default Dashboard;
