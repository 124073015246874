export const listReservations = /* GraphQL */ `
  query ListReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        friendlyId
        createDatetime
       
        checkInDate
        checkOutDate
        totalAmount
        
        status
        roomId
        userId
        User {
          id
          firstName
          lastName
         
          email
          phone
        
        }
        createdAt
        updatedAt
        Room {
          id
          title
          Property {
            shortDescription
          }
         
        }
      }
      nextToken
    }
  }
`;