import { DropzoneImageInput } from '@components';
import React from 'react'
import { Divider } from '@material-ui/core';
import { useStyles, useImageFile } from '@config';
import { useUser } from 'modules/User/UserContext';

const AvatarImage = () => {
  const classes = useStyles()
  const {
    avatarImage,
    setAvatarImage,
  } = useUser()
  const [, setImageFile] = useImageFile()

  function onDrop(acceptedFiles, fileRejections, event) {
    if (acceptedFiles[0]) {
      const image = new Image();
      setImageFile(acceptedFiles[0], image)

      image.onload = () => {
        setAvatarImage({
          file: acceptedFiles[0],
          url: image.src
        })
      }
    }
  }

  return (
    <>
      <section>
        <div>
          <h2>
            {'Avatar'}
            <Divider className={classes['mt0.5']} />
          </h2>
        </div>
        <DropzoneImageInput
          widthImage={'320px'}
          onDrop={onDrop}
          imageURL={avatarImage.url}
        />
      </section>
    </>
  )
}

export default AvatarImage;
