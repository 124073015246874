import * as Yup from 'yup';
import { AmenitySchema } from '@modules/Amenity/AmenityForm'

const PropertySchema = Yup.object().shape({
  title: Yup.string()
    .required('Required'),
  baths: Yup.number()
    .required('Required'),
  beds: Yup.number().required('Required'),
  shortDescription: Yup.string(),
  address: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  amenities: Yup.array().of(AmenitySchema)
});

export default PropertySchema;
