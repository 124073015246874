import React, { useMemo } from "react";
import clsx from "clsx";
import { useStyles } from "@config";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  TextareaAutosize,
  TextField,
  Fab,
  List,
} from "@material-ui/core";
import { useProperty } from "../PropertyContext";
import { property } from "../PropertyContext/context.store";
import { useFormik } from "formik";
import PropertyRoomList from "../PropertyRoomList";
import { useEffect } from "react";
import PropertySchema from "./PropertyForm.schema";
import PropertyHeroImages from "./PropertyHeroImages";
import { Storage } from "aws-amplify";
import { useHistory } from "react-router-dom";
import PropertyListImage from "./PropertyListImage";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { useLayout } from "@components/Layout";
import { AmenityArrayForm } from "@modules/Amenity";
import AddIcon from "@material-ui/icons/Add";
import { useAmenity } from "@modules/Amenity/AmenityContext";
import { constructImageUrl } from "@components/Images";

const PropertyForm = ({ match }) => {
  const { amenity } = useAmenity();
  const [dataLayout] = useLayout();
  let history = useHistory();
  const {
    isLoading,
    setIsLoading,
    fetchRoomsByProperty,
    fetchProperty,
    insertProperty,
    updateProperty,
    rooms,
    setRooms,
    setCoverImage,
    coverImageBlob,
    propertyImages,
    cleanRoomForm,
  } = useProperty();
  const {
    errors,
    setValues,
    values: formValues,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: property,
    validationSchema: PropertySchema,
    onSubmit,
  });
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  async function onSubmit(values) {
    setIsLoading(true);
    if (match.params.id === "new") {
      await insertProperty(
        {
          ...values,
          id: null,
          images: [],
        },
        coverImageBlob,
        propertyImages
      );
    } else {
      await updateProperty(
        {
          ...values,
          images: [],
        },
        coverImageBlob,
        propertyImages
      );
    }
    setIsLoading(false);
    setTimeout(() => {
      cleanRoomForm();
      history.push("/properties");
    }, 500);
  }

  useEffect(() => {
    (async () => {
      if (match.params.id !== "new") {
        setIsLoading(true);
        const property = await fetchProperty(match.params.id);
        const rooms = await fetchRoomsByProperty(property.Room);

        if (property.heroImage) {
          // const image = await Storage.get(property.heroImage.key);
          setCoverImage(constructImageUrl(property.heroImage));  
        }

        setValues({
          id: property.id,
          title: property.title,
          baths: property.baths,
          beds: property.beds,
          city: property.city,
          state: property.state,
          postalCode: property.postalCode,
          address: property.address,
          shortDescription: property.shortDescription || "",
          description: property.description || "",
          amenities: property.Amenities.items,
        });
        setRooms(rooms);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  function onCancel() {
    cleanRoomForm();
    history.push("/properties");
  }

  function onNewAmenities() {
    const amenities = [amenity].concat(formValues.amenities);

    setValues({
      ...formValues,
      amenities,
    });
  }

  function handleAmenityChange(e, index) {
    const {
      target: { name, value },
    } = e;

    formValues.amenities[index] = {
      ...formValues.amenities[index],
      [name]: value,
    };

    setValues({
      ...formValues,
    });
  }

  function onDeleteAmenity(index) {
    const amenities = formValues.amenities
      .map((amenity, idx) => {
        if (index === idx) {
          return {
            ...amenity,
            delete: true
          }
        }

        return amenity
      })

    setValues({
      ...formValues,
      amenities,
    });
  }

  const amenitiesMemo = useMemo(() => formValues.amenities, [
    formValues.amenities,
  ]);

  return (
    <Grid container spacing={1}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} style={{ marginBottom: "1em" }}>
            <Grid item xs={12}>
              <List
                className={clsx(
                  dataLayout.openDrawer && classes.drawerPaperFormActionOpen,
                  !dataLayout.openDrawer && classes.drawerPaperFormActionClose
                )}
              >
                <Fab
                  size="small"
                  color="primary"
                  aria-label="save"
                  type="submit"
                >
                  <SaveIcon />
                </Fab>

                <Fab
                  size="small"
                  aria-label="cancel"
                  style={{ marginTop: "0.5em" }}
                  onClick={onCancel}
                >
                  <CloseIcon />
                </Fab>
              </List>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper className={fixedHeightPaper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.title !== undefined}
                        name="title"
                        label="Title"
                        value={formValues.title}
                        helperText={errors.title ? errors.title : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.beds !== undefined}
                        name="beds"
                        type={"number"}
                        label="Beds"
                        value={formValues.beds}
                        InputProps={{ inputProps: { min: 0 } }}
                        helperText={errors.beds ? errors.beds : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.baths !== undefined}
                        name="baths"
                        type={"number"}
                        label="Baths"
                        value={formValues.baths}
                        InputProps={{ inputProps: { min: 0 } }}
                        helperText={errors.baths ? errors.baths : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.address !== undefined}
                        name="address"
                        label="Address"
                        value={formValues.address}
                        helperText={errors.address ? "Campo Requerido" : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.city !== undefined}
                        name="city"
                        label="City"
                        value={formValues.city}
                        helperText={errors.city ? "Campo Requerido" : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} lg={2} xl={3}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.state !== undefined}
                        name="state"
                        label="State"
                        value={formValues.state}
                        helperText={errors.state ? "Campo Requerido" : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3} xl={4}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.postalCode !== undefined}
                        name="postalCode"
                        label="Postal Code"
                        value={formValues.postalCode}
                        helperText={errors.postalCode ? "Campo Requerido" : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <section>
                      <TextField
                        className={[classes.textField].join(" ")}
                        error={errors.shortDescription !== undefined}
                        name="shortDescription"
                        label="Short Description"
                        value={formValues.shortDescription}
                        helperText={errors.shortDescription ? "Campo Requerido" : ""}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </section>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.mt1}>
                  <Grid item xs={12}>
                    <section>
                      <TextareaAutosize
                        className={[classes.textField].join(" ")}
                        name="description"
                        rowsMin={12}
                        value={formValues.description}
                        onChange={handleChange}
                        placeholder="Enter Description"
                      />
                    </section>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {match.params.id !== "new" && (
              <Grid item xs={12} className={classes.mt1}>
                <Paper className={fixedHeightPaper}>
                  <section
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <h2>{`Amenities`}</h2>
                      <div
                        className={clsx(classes["ml1"], classes["mt0.5"])}
                        onClick={() => onNewAmenities()}
                      >
                        <Fab
                          size="small"
                          color="default"
                          aria-label="add-amenity"
                          type="button"
                        >
                          <AddIcon />
                        </Fab>
                      </div>
                    </div>
                  </section>

                  <section>
                    <AmenityArrayForm
                      key={amenity.id}
                      amenitiesValues={amenitiesMemo}
                      errors={errors}
                      handleChange={handleAmenityChange}
                      onDeleteAmenity={onDeleteAmenity}
                    />
                  </section>
                </Paper>
              </Grid>
            )}

            <Grid item xs={12} className={classes.mt1}>
              <Paper className={fixedHeightPaper}>
                <h2>{`Images`}</h2>

                <section>
                  <PropertyHeroImages />
                </section>

                <section className={classes.mt5}>
                  <PropertyListImage />
                </section>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Grid>

      {rooms.length > 0 && (
        <Grid item xs={12} className={classes.mt1}>
          <PropertyRoomList rooms={rooms} />
        </Grid>
      )}
    </Grid>
  );
};

export default PropertyForm;
