import React, { useEffect, useState } from "react";
import clsx from "clsx";
// import { useStyles } from '@components/SideMenu/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "@config";
import usePriceFormStyles from "./style";
import { Select } from "@components";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { Switch } from "react-router-dom";
import { update } from "draft-js/lib/DefaultDraftBlockRenderMap";
import { useRoom } from "../RoomContext";

var myHeaders = new Headers();
myHeaders.append(
  "Authorization",
  "Bearer " + process.env.REACT_APP_STRIPE_API_KEY
  // `Bearer ${process.env.STRIPE_API_KEY}`
);
var requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};

// let mbProducts = ["Processing Fee", "Cleaning Fees", "Room Price (4 Weeks)"];

const RoomPriceForm = ({
  formValues,
  errors,
  handleChange,
  onDeletePrice,
  propertyName,
}) => {
  const classes = useStyles();
  const priceFormClasses = usePriceFormStyles();
  const [products, setProducts] = useState();
  const [prices, setPrices] = useState([]);
  const [priceList, setPriceList] = useState();
  const [complete, setComplete] = useState(false);
  const [mbProducts, setMbProducts] = useState([
    "Processing Fee",
    "Cleaning Fees",
    "Room Price (4 Weeks)",
  ]);
  const {
    isLoading,
    setIsLoading,
    fetchRoom,
    insertRoom,
    updateRoom,
    setCoverImage,
    coverImage,
    roomImages,
    cleanRoomForm,
    room,
    fetchProperties,
    properties,
  } = useRoom();
  const handleChangeLocal = (e, idx) => {
    const thisPrice = prices.filter((price) => price.id === e.target.value);
    const updatedPrice = thisPrice[0].unit_amount / 100;

    formValues.prices[idx].displayAmount = updatedPrice;
    formValues.prices[idx].amount = updatedPrice;
    handleChange(e, idx);
  };

  useEffect(() => {
    if (errors.prices) {
      console.log(errors.prices);
    }
  }, [errors]);

  useEffect(() => {
    const getStripeData = (products) => {
      
      fetch("https://api.stripe.com/v1/products?limit=1000", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let data = JSON.parse(result);

          data.data.map((product) => {
            if (products.includes(product.description)) {
              fetch(
                `https://api.stripe.com/v1/prices?product=${product.id}&limit=100`,
                requestOptions
              )
                .then((response) => response.text())
                .then((result) => {
                  let data = JSON.parse(result);

                  data.data.map(
                    (price) => (price["productName"] = product.description)
                  );

                  setPrices((prices) => [...prices.concat(data.data)]);
                })
                .catch((error) => console.log("error", error));
            }
          });

          // setPrices(pricesTemp);

          setComplete(true);
        })
        .catch((error) => console.log("error", error));
    };

    if (propertyName) {      
      getStripeData(mbProducts.concat(propertyName));
    }
  }, [propertyName]);

  useEffect(() => {
    setPriceList(
      prices
        .map((price) => {
          return {
            id: price.id,
            name: `${price.productName} ${price.nickname} - ${
              price.unit_amount / 100
            } ${price.recurring ? "Recurring" : "Initial"}`,
          };
        })
        .sort()
    );
  }, [prices]);
  // const getPriceList = (price) => {
  //   let tmpPriceList = filterPriceList(price).filter(price=>price.name.includes(price.amount))
  //   return tmpPriceList;
  // }
  const getPriceList = (price) => {
    
    let title = price.title.toLowerCase();
    let type = title.includes("cleaning")
      ? "CLEANING"
      : title.includes("processing")
      ? "PROCESSING"
      : "ROOM";

    let reccurrenceType =
      price.recurrenceType.toUpperCase() !== "RECURRING"
        ? "INITIAL"
        : "RECURRING";

    let cleaningPrices = priceList.filter((price) =>
      price.name.toLowerCase().includes("cleaning")
    );

    let processingPrices = priceList.filter((price) =>
      price.name.toLowerCase().includes("processing")
    );

    let roomPrices = priceList.filter((price) =>
      price.name.toLowerCase().includes("room") || price.name.toLowerCase().includes("cottage") || price.name.toLowerCase().includes("loft")
    );
    switch (true) {
      case type === "CLEANING" && reccurrenceType === "INITIAL":
        return cleaningPrices.filter((price) =>
          price.name.toUpperCase().includes("INITIAL")
        );
      case type === "CLEANING" && reccurrenceType === "RECURRING":
        return cleaningPrices.filter((price) =>
          price.name.toUpperCase().includes("RECURRING")
        );
      case type === "PROCESSING" && reccurrenceType === "INITIAL":
        return processingPrices.filter((price) =>
          price.name.toUpperCase().includes("INITIAL")
        );
      case type === "PROCESSING" && reccurrenceType === "RECURRING":
        return processingPrices.filter((price) =>
          price.name.toUpperCase().includes("RECURRING")
        );
      case type === "ROOM" && reccurrenceType === "INITIAL":
        return roomPrices.filter((price) =>
          price.name.toUpperCase().includes("INITIAL")
        );
      case type === "ROOM" && reccurrenceType === "RECURRING":
        return roomPrices.filter((price) =>
          price.name.toUpperCase().includes("RECURRING")
        );
      case type === "GUEST" && reccurrenceType === "OPTIONAL":
        return roomPrices.filter((price) =>
          price.name.toUpperCase().includes("GUEST")
        );
      default:
        return priceList.sort();
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {formValues.prices &&
              formValues.prices.map((price, idx) => (
                <Accordion key={idx}>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon />}
                    aria-controls="panel-price-content"
                  >
                    <Typography className={priceFormClasses.heading}>
                      {price.title} -{" "}
                      {price.recurrenceType === "RECURRING"
                        ? "Recurring"
                        : "Initial"}
                    </Typography>

                    {errors.prices && errors.prices[idx] && (
                      <Typography className={priceFormClasses.headingError}>
                        {"Errors found in form"}
                      </Typography>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <section
                          style={{
                            paddingBottom: "1em",
                            paddingLeft: "0.5em",
                          }}
                          onClick={() => onDeletePrice(price.id)}
                        >
                          <Fab
                            size="small"
                            color="primary"
                            aria-label="save"
                            type="button"
                          >
                            <DeleteOutlineOutlinedIcon />
                          </Fab>
                        </section>
                      </Grid>

                      <Grid item xs={12}>
                        <section>
                          <TextField
                            className={[classes.textField].join(" ")}
                            error={
                              errors.prices &&
                              errors.prices[idx] &&
                              errors.prices[idx].title !== undefined
                            }
                            name="title"
                            label="Title"
                            value={price.title}
                            helperText={
                              errors.prices &&
                              errors.prices[idx] &&
                              (errors.prices[idx].title
                                ? errors.prices[idx].title
                                : "")
                            }
                            variant="outlined"
                            onChange={(e) => handleChange(e, idx)}
                          />
                        </section>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <section>
                          <TextField
                            className={[classes.textField].join(" ")}
                            error={
                              errors.prices &&
                              errors.prices[idx] &&
                              errors.prices[idx].amount !== undefined
                            }
                            name="amount"
                            type={"number"}
                            label="Amount"
                            value={price.amount}
                            InputProps={{ inputProps: { min: 0 } }}
                            helperText={
                              errors.prices &&
                              errors.prices[idx] &&
                              (errors.prices[idx].amount
                                ? errors.prices[idx].amount
                                : "")
                            }
                            variant="outlined"
                            onChange={(e) => handleChange(e, idx)}
                          />
                        </section>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <section>
                          <Select
                            name="amountType"
                            label={"Amount Type"}
                            value={price.amountType || ""}
                            error={
                              errors.prices &&
                              errors.prices[idx] &&
                              errors.prices[idx].amountType !== undefined
                            }
                            helperText={
                              errors.prices &&
                              errors.prices[idx] &&
                              (errors.prices[idx].amountType
                                ? errors.prices[idx].amountType
                                : "")
                            }
                            values={[
                              {
                                id: "FLAT",
                                name: "Flat",
                              },
                              {
                                id: "PERCENTAGE",
                                name: "Percentage",
                              },
                            ]}
                            onChange={(e) => handleChange(e, idx)}
                          />
                        </section>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <section>
                          <TextField
                            className={[classes.textField].join(" ")}
                            error={
                              errors.prices &&
                              errors.prices[idx] &&
                              errors.prices[idx].displayAmount !== undefined
                            }
                            name="displayAmount"
                            type={"number"}
                            label="Display Amount"
                            value={price.displayAmount}
                            InputProps={{ inputProps: { min: 0 } }}
                            helperText={
                              errors.prices &&
                              errors.prices[idx] &&
                              (errors.prices[idx].displayAmount
                                ? errors.prices[idx].displayAmount
                                : "")
                            }
                            variant="outlined"
                            onChange={(e) => handleChange(e, idx)}
                          />
                        </section>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <section>
                          <Select
                            name="type"
                            label={"Type"}
                            value={price.type || ""}
                            error={
                              errors.prices &&
                              errors.prices[idx] &&
                              errors.prices[idx].type !== undefined
                            }
                            helperText={
                              errors.prices &&
                              errors.prices[idx] &&
                              (errors.prices[idx].type
                                ? errors.prices[idx].type
                                : "")
                            }
                            values={[
                              {
                                id: "PRODUCT",
                                name: "Product",
                              },
                              {
                                id: "DISCOUNT",
                                name: "Discount",
                              },
                              {
                                id: "FEE",
                                name: "Fee",
                              },
                              {
                                id: "GUEST",
                                name: "Guest",
                              },
                            ]}
                            onChange={(e) => handleChange(e, idx)}
                          />
                        </section>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <section>
                          <TextField
                            className={[classes.textField].join(" ")}
                            error={
                              errors.prices &&
                              errors.prices[idx] &&
                              errors.prices[idx].increment !== undefined
                            }
                            helperText={
                              errors.prices &&
                              errors.prices[idx] &&
                              (errors.prices[idx].increment
                                ? errors.prices[idx].increment
                                : "")
                            }
                            name="increment"
                            type={"number"}
                            label="Increment"
                            value={price.increment || 0}
                            InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                            onChange={(e) => handleChange(e, idx)}
                          />
                        </section>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <section>
                          <Select
                            name="incrementType"
                            label={"Increment Type"}
                            value={price.incrementType || ""}
                            error={
                              errors.prices &&
                              errors.prices[idx] &&
                              errors.prices[idx].incrementType !== undefined
                            }
                            helperText={
                              errors.prices &&
                              errors.prices[idx] &&
                              (errors.prices[idx].incrementType
                                ? errors.prices[idx].incrementType
                                : "")
                            }
                            values={[
                              {
                                id: "DAY",
                                name: "Day",
                              },
                              {
                                id: "WEEK",
                                name: "Week",
                              },
                              {
                                id: "MONTH",
                                name: "Month",
                              },
                            ]}
                            onChange={(e) => handleChange(e, idx)}
                          />
                        </section>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <section>
                          <TextField
                            className={[classes.textField].join(" ")}
                            name="stripePriceId"
                            label="Stripe Reference"
                            value={price.stripePriceId}
                            variant="outlined"
                            onChange={(e) => handleChange(e, idx)}
                          />
                          <Select
                            name="stripePriceId"
                            label={"Stripe Reference"}
                            value={price.stripePriceId || ""}
                            error={
                              errors.prices &&
                              errors.prices[idx] &&
                              errors.prices[idx].recurrenceType !== undefined
                            }
                            helperText={
                              errors.prices &&
                              errors.prices[idx] &&
                              (errors.prices[idx].recurrenceType
                                ? errors.prices[idx].recurrenceType
                                : "")
                            }
                            values={getPriceList(price)}
                            onChange={(e) => handleChangeLocal(e, idx)}
                          />
                        </section>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <section>
                          <Select
                            name="recurrenceType"
                            label={"Recurrence Type"}
                            value={price.recurrenceType || ""}
                            error={
                              errors.prices &&
                              errors.prices[idx] &&
                              errors.prices[idx].recurrenceType !== undefined
                            }
                            helperText={
                              errors.prices &&
                              errors.prices[idx] &&
                              (errors.prices[idx].recurrenceType
                                ? errors.prices[idx].recurrenceType
                                : "")
                            }
                            values={[
                              {
                                id: "ONETIME",
                                name: "One Time",
                              },
                              {
                                id: "RECURRING",
                                name: "Recurring",
                              },
                              {
                                id: "OPTIONAL",
                                name: "Optional",
                              },
                            ]}
                            onChange={(e) => handleChange(e, idx)}
                          />
                        </section>
                      </Grid>

                      <Grid item xs={12}>
                        <section>
                          <TextareaAutosize
                            className={[classes.textField].join(" ")}
                            name="description"
                            rowsMin={12}
                            value={price.description}
                            onChange={(e) => handleChange(e, idx)}
                            placeholder="Enter Description"
                          />
                        </section>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RoomPriceForm;
