import * as Yup from "yup";
import { AmenitySchema } from '@modules/Amenity/AmenityForm'

const priceValidationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  amount: Yup.number().required('Required'),
  amountType: Yup.string().required('Required'),
  displayAmount: Yup.number().required('Required'),
  type: Yup.string().required('Required'),
  increment: Yup.number().required('Required'),
  incrementType: Yup.string().required('Required').nullable(),
  stripePriceId: Yup.string(),
  recurrenceType: Yup.string().required('Required'),
  description: Yup.string(),
})

const RoomSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  discount: Yup.number().required("Required"),
  price: Yup.number().required("Required"),
  roomPropertyId: Yup.string().required("Required"),
  prices: Yup.array().of(priceValidationSchema),
  amenities: Yup.array().of(AmenitySchema),
  bedType: Yup.string(),
  active: Yup.boolean(),
  sqft: Yup.number().required('Required'),
});

export default RoomSchema;
