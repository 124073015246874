const marginLeft = {
  "ml0.5": {
    marginLeft: "0.5em",
  },
  ml1: {
    marginLeft: "1em",
  },
  "ml1.5": {
    marginLeft: "1.5em",
  },
  ml2: {
    marginLeft: "2em",
  },
  "ml2.5": {
    marginLeft: "2.5em",
  },
  ml3: {
    marginLeft: "3em",
  },
  'ml3.5': {
    marginLeft: "3.5em",
  },
  ml4: {
    marginLeft: "4em",
  },
  ml5: {
    marginLeft: "5em",
  },
  ml10: {
    marginLeft: "10em",
  },
  ml15: {
    marginLeft: "15em",
  },
};

export default marginLeft;
