import React, { useMemo } from "react";
// import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { Table } from "@components";
import { useHistory } from "react-router-dom";
import { sortingBool } from "@config";

const PropertyRoomList = ({ rooms }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Room List",
        columns: [
          {
            Header: `id`,
            accessor: "id",
          },
          {
            Header: `Title`,
            accessor: "title",
          },
          {
            Header: `Price`,
            accessor: "price",
            Cell: ({ value }) => {
              return (
                <div>
                  {`$${value}`}
                </div>
              )
            },
          },
          {
            Header: "Discount",
            accessor: "discount",
          },
          {
            Header: "Has Bathroom",
            accessor: "hasBathroom",
            Cell: ({ value }) => {
              return (
                <div>
                  {value ? 'Yes' : 'No'}
                </div>
              )
            },
            sortType: (a, b) => {
              return sortingBool(a.original.hasBathroom, b.original.hasBathroom)
            },
          },
        ],
      },
    ],
    []
  );
  let history = useHistory();

  function goTo(values) {
    history.push(`/rooms/${values.id}`);
  }

  const data = React.useMemo(() => rooms, [rooms]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={data}
          onRowClick={(values) => goTo(values)}
          onAddRow={() => { goTo({ id: 'new' })}}
        />
      </Grid>
    </Grid>
  );
};

export default PropertyRoomList;
