import { useState } from "react";

function useImageFile() {
  const [imageFile] = useState("");

  function setBase64File(file, image) {
    const reader = new FileReader();

    reader.onabort = () => console.error("file reading was aborted");
    reader.onerror = () => console.error("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      image.src = reader.result;
    };

    image.onabort = () => console.error("image was aborted");
    image.onerror = () => console.error("image has failed");

    reader.readAsDataURL(file);
  }

  return [imageFile, setBase64File];
}

export default useImageFile;
