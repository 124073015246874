import { createContext, useReducer, useMemo, useContext, useEffect } from "react";
import { initialState, roomReducer } from "./context.store";
import RoomService from "./RoomService";
import { putFile } from "@config";
import { Storage } from "aws-amplify";
import { useLayout } from "@components/Layout";
import { useAmenity } from "modules/Amenity/AmenityContext";
import { constructImageUrl } from "@components/Images";

export const RoomContext = createContext(initialState);

RoomContext.displayName = "RoomContext";

export const RoomProvider = (props) => {
  const { insertAmenity, updateAmenity, deleteAmenity } = useAmenity();
  const [state, dispatch] = useReducer(roomReducer, initialState);

  const setRoom = (room) => dispatch({ type: "SET_ROOM", room });

  const setRooms = (rooms) => dispatch({ type: "SET_ROOMS", rooms });

  const setIsLoading = (isLoading) =>
    dispatch({ type: "SET_IS_LOADING", isLoading });

  const setCoverImage = (coverImage) =>
    dispatch({ type: "SET_COVER_IMAGE", coverImage });

  const setRoomImages = (roomImages) =>
    dispatch({ type: "SET_ROOM_IMAGES", roomImages });

  const cleanRoomForm = () => dispatch({ type: "CLEAN_ROOM_FORM" });

  const setProperties = properties =>
    dispatch({ type: "SET_PROPERTIES", properties });

  const fetchRooms = async () => {
    const roomService = new RoomService();

    try {
      const data = await roomService.getList();
      
      return data;
    } catch (err) {}
  };

  const fetchRoom = async (roomId) => {
    const roomService = new RoomService();

    try {
      const data = await roomService.getRoom(roomId);
 
      if (data.images) {
        const _roomImages = [];
        for (const image of data.images) {
          // const imageURL = await Storage.get(image.key);
          const imageURL = constructImageUrl(image);
          _roomImages.push({
            file: null,
            url: imageURL,
            key: image,            
            action: "KEEP",
          });
        }

        setRoomImages(_roomImages);
      }

      return data;
    } catch (err) {}
  };

  const fetchProperties = async () => {
    const roomService = new RoomService();

    try {
      const data = await roomService.getPropertyList();
      setProperties(data)

      return data;
    } catch (err) {}
  };

  const insertRoom = async (room, heroFile, imagesFile) => {
    const roomService = new RoomService()
    try {
      if (heroFile.file) {
        const heroImage = await putFile(heroFile.file);
        room.heroImage= heroImage.key;
      }

      if (!room.heroImage) {
        room.heroImage = null;
      }

      room.images = await roomService.parseRoomImages(imagesFile)
      const data = await roomService.insertRoom(room);
      return data;
    } catch (err) {
      console.log('err', err)
    }
  };

  const updateRoom = async (room, heroFile, imagesFile) => {
    const roomService = new RoomService();

    try {
      if (heroFile.file) {
        const heroImage = await putFile(heroFile.file);
        room.heroImage= heroImage.key;
      }

      if (!room.heroImage) {
        room.heroImage = null;
      }

      if (room.amenities) {
        const amenities = room.amenities;
        for (const amenity of amenities) {
          if (!amenity.delete) {
            if (!amenity.id) {
              delete amenity.id;
              await insertAmenity(amenity, room.id, "room");
            } else {
              await updateAmenity(amenity, room.id, "room");
            }
          }

          if (amenity.delete) {
            await deleteAmenity(amenity.id);
          }
        }

        delete room.amenities;
      }

      room.images = await roomService.parseRoomImages(imagesFile);
      
      const data = await roomService.updateRoom(room);
      return data;
    } catch (err) {
      console.log("err", err);
    }
  };

  const value = useMemo(
    () => ({
      ...state,
      setRoom,
      setRooms,
      setIsLoading,
      fetchRooms,
      fetchRoom,
      insertRoom,
      updateRoom,
      setCoverImage,
      setRoomImages,
      cleanRoomForm,
      fetchProperties,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <RoomContext.Provider value={value} {...props} />;
};

export const useRoom = () => {
  const context = useContext(RoomContext);
  if (context === undefined) {
    throw new Error(`useRoom must be used within a RoomProvider`);
  }

  return context;
};

export const ManagedRoomContext = ({ children }) => {
  const [, setDataLayout] = useLayout();

  useEffect(() => {
    setDataLayout((datalayout) => ({
      ...datalayout,
      drawerTitle: "Rooms",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <RoomProvider>{children}</RoomProvider>;
};
