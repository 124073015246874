import React from "react";
import "../../index.css";
import {
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import BookIcon from "@material-ui/icons/Book";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import { useLocation } from "react-router-dom";

export const MainListItems = ({ onClickList }) => {
  const location = useLocation();
  const matchPath = (path) => location.pathname.includes(path);

  return (
    <div>
      <Tooltip title="Dashboard" aria-label="dashboard" placement="right-start">
        <ListItem button onClick={() => onClickList("dashboard")} style={(matchPath('dashboard')) ? {backgroundColor:"#eee"} : null}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </Tooltip>

      <Tooltip
        title="Properties"
        aria-label="properties"
        placement="right-start"
      >
        <ListItem button onClick={() => onClickList("properties")} style={(matchPath('properties')) ? {backgroundColor:"#eee"} : null}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Properties" />
        </ListItem>
      </Tooltip>

      <Tooltip title="Rooms" aria-label="rooms" placement="right-start">
        <ListItem button onClick={() => onClickList("rooms")} style={(matchPath('rooms')) ? {backgroundColor:"#eee"} : null}>
          <ListItemIcon>
            <MeetingRoomIcon />
          </ListItemIcon>
          <ListItemText primary="Rooms" />
        </ListItem>
      </Tooltip>

      <Tooltip title="Users" aria-label="users" placement="right-start">
        <ListItem button onClick={() => onClickList("users")} style={(matchPath('users')) ? {backgroundColor:"#eee"} : null}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </Tooltip>

      <Tooltip
        title="Reservations"
        aria-label="reservations"
        placement="right-start"
      >
        <ListItem button onClick={() => onClickList("reservations")} style={(matchPath('reservations')) ? {backgroundColor:"#eee"} : null}>
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Reservation" />
        </ListItem>
      </Tooltip>

      <Tooltip title="Content" aria-label="content" placement="right-start">
        <ListItem button onClick={() => onClickList("content")} style={(matchPath('content')) ? {backgroundColor:"#eee"} : null}>
          <ListItemIcon>
            <WebAssetIcon />
          </ListItemIcon>
          <ListItemText primary="Content" />
        </ListItem>
      </Tooltip>
      
    </div>
  );
};

export const secondaryListItems = (
  <div>
    {/* <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem> */}
  </div>
);
