import { putFile } from "@config";
import {
  createContext,
  useReducer,
  useMemo,
  useContext,
  useEffect,
} from "react";
import { initialState, propertyReducer } from "./context.store";
import PropertyService from "./PropertyService";
import { Storage } from "aws-amplify";
import { useLayout } from "@components/Layout";
import { useAmenity } from "modules/Amenity/AmenityContext";
import { constructImageUrl } from "@components/Images";

export const PropertyContext = createContext(initialState);

PropertyContext.displayName = "PropertyContext";

export const PropertyProvider = (props) => {
  const { insertAmenity, updateAmenity, deleteAmenity } = useAmenity();
  const [state, dispatch] = useReducer(propertyReducer, initialState);

  const setProperty = (property) =>
    dispatch({ type: "SET_PROPERTY", property });

  const setProperties = (properties) =>
    dispatch({ type: "SET_PROPERTIES", properties });

  const setIsLoading = (isLoading) =>
    dispatch({ type: "SET_IS_LOADING", isLoading });

  const setRooms = (rooms) =>
    dispatch({ type: "SET_ROOMS_BY_PROPERTY", rooms });

  const setCoverImage = (coverImage) =>
    dispatch({ type: "SET_COVER_IMAGE", coverImage });

  const setCoverImageBLOB = (coverImageBlob) =>
    dispatch({ type: "SET_COVER_IMAGE_BLOB", coverImageBlob });

  const setPropertyImages = (propertyImages) =>
    dispatch({ type: "SET_PROPERTY_IMAGES", propertyImages });

  const cleanRoomForm = () => dispatch({ type: "CLEAN_ROOM_FORM" });

  const fetchProperties = async () => {
    const propertyService = new PropertyService();

    try {
      const data = await propertyService.getList();
      return data;
    } catch (err) {}
  };

  const fetchRoomsByProperty = async (roomData) => {
    // const propertyService = new PropertyService();

    const { items } = roomData;
    return items;
    // try {
    //   const data = await propertyService.getRooms(propertyId);
    //   return data;
    // } catch (err) {}
  };

  const fetchProperty = async (propertyId) => {
    const propertyService = new PropertyService();

    try {
      const data = await propertyService.getProperty(propertyId);
      if (data.images) {
        const _propertyImages = [];
        for (const image of data.images) {
          const imageURL = constructImageUrl(image);
          // const imageURL = await Storage.get(image.key);
          _propertyImages.push({
            file: null,
            url: imageURL,
            bucket: image.bucket,
            description: image.description,
            key: image,
            region: image.region,
            action: "KEEP",
          });
        }

        setPropertyImages(_propertyImages);
      }

      return data;
    } catch (err) {}
  };

  const insertProperty = async (property, heroFile, imagesFile) => {
    const propertyService = new PropertyService();

    try {
      if (heroFile) {
        const heroImage = await putFile(heroFile);
        property.heroImage = heroImage.key;
      }

      // if (!property.heroImage) {
      //   property.heroImage = null;
      // }

      property.images = await propertyService.parsePropertyImages(imagesFile);

      const data = await propertyService.insertProperty(property);
      setCoverImageBLOB(null);
      return data;
    } catch (err) {
      console.log("err", err);
    }
  };

  const updateProperty = async (property, heroFile, imagesFile) => {
    const propertyService = new PropertyService();

    try {
      if (heroFile) {
        const heroImage = await putFile(heroFile);
        property.heroImage = heroImage.key;
      }

      // if (!property.heroImage) {
      //   property.heroImage = null;
      // }

      if (property.amenities) {
        const amenities = property.amenities;
        for (const amenity of amenities) {
          if (!amenity.delete) {
            if (!amenity.id) {
              delete amenity.id;
              await insertAmenity(amenity, property.id, "property");
            } else {
              await updateAmenity(amenity, property.id, "property");
            }
          }

          if (amenity.delete) {
            await deleteAmenity(amenity.id);
          }
        }

        delete property.amenities;
      }

      property.images = await propertyService.parsePropertyImages(imagesFile);
      const data = await propertyService.updateProperty(property);

      setCoverImageBLOB(null);
      return data;
    } catch (err) {
      console.log("err", err);
    }
  };

  const value = useMemo(
    () => ({
      ...state,
      setProperty,
      setProperties,
      setIsLoading,
      fetchProperties,
      fetchRoomsByProperty,
      fetchProperty,
      insertProperty,
      updateProperty,
      setRooms,
      setCoverImage,
      setCoverImageBLOB,
      setPropertyImages,
      cleanRoomForm,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <PropertyContext.Provider value={value} {...props} />;
};

export const useProperty = () => {
  const context = useContext(PropertyContext);
  if (context === undefined) {
    throw new Error(`useProperty must be used within a PropertyProvider`);
  }

  return context;
};

export const ManagedPropertyContext = ({ children }) => {
  const [, setDataLayout] = useLayout();

  useEffect(() => {
    setDataLayout((datalayout) => ({
      ...datalayout,
      drawerTitle: "Properties",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PropertyProvider>{children}</PropertyProvider>;
};
