import React from "react";
import { Route } from "react-router-dom";
import { PropertyList, PropertyForm } from "../index";
import { ManagedPropertyContext } from "../PropertyContext";
import { ManagedAmenityContext } from "@modules/Amenity/AmenityContext";

const PropertyRoute = () => {
  return (
    <ManagedAmenityContext>
      <ManagedPropertyContext>
        <Route path="/properties" component={PropertyList} exact />
        <Route path="/properties/:id" component={PropertyForm} />
      </ManagedPropertyContext>
    </ManagedAmenityContext>
  );
};

export default PropertyRoute;
