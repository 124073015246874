import { createContext, useReducer, useMemo, useContext } from "react";
import { initialState, amenityReducer } from "./context.store";
import AmenityService from "./AmenityService";

export const AmenityContext = createContext(initialState);

AmenityContext.displayName = "AmenityContext";

export const AmenityProvider = (props) => {
  const [state, dispatch] = useReducer(amenityReducer, initialState);

  const setAmenity = (amenity) =>
    dispatch({ type: "SET_AMENITIY", amenity });

  const setAmenities = (amenities) =>
    dispatch({ type: "SET_AMENITIES", amenities });

  const setIsLoading = (isLoading) =>
    dispatch({ type: "SET_IS_LOADING", isLoading });

  const cleanAmenityForm = () =>
    dispatch({ type: "CLEAN_AMENITY_FORM" });

  const insertAmenity = async (_amenity, id, type = 'property') => {
    const amenityService = new AmenityService();

    try {
      const amenity = {
        ..._amenity,
        sortOrder: parseInt(_amenity.sortOrder, 10)
      };

      if (type === 'property') amenity.amenityPropertyId = id
      if (type === 'room') amenity.amenityRoomId = id

      const data = await amenityService.insertAmenity(amenity);
      return data;
    } catch (err) {
      console.log("err", err);
    }
  };

  const updateAmenity = async (_amenity, id, type = 'property') => {
    const amenityService = new AmenityService();

    try {
      const amenity = {
        ..._amenity,
        sortOrder: parseInt(_amenity.sortOrder, 10)
      };

      if (type === 'property') amenity.amenityPropertyId = id
      if (type === 'room') amenity.amenityRoomId = id
      delete amenity.createdAt;
      delete amenity.updatedAt;


      const data = await amenityService.updateAmenity(amenity);
      return data;
    } catch (err) {
      console.log("err", err);
    }
  };

  const deleteAmenity = async (id) => {
    const amenityService = new AmenityService();

    try {
      const data = await amenityService.deleteAmenity(id);
      return data;
    } catch (err) {
      console.log("err", err);
    }
  }

  const value = useMemo(
    () => ({
      ...state,
      setAmenity,
      setAmenities,
      setIsLoading,
      cleanAmenityForm,
      insertAmenity,
      updateAmenity,
      deleteAmenity,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <AmenityContext.Provider value={value} {...props} />;
};

export const useAmenity = () => {
  const context = useContext(AmenityContext);
  if (context === undefined) {
    throw new Error(`useAmenity must be used within a AmenityProvider`);
  }

  return context;
};

export const ManagedAmenityContext = ({ children }) => {
  return <AmenityProvider>{children}</AmenityProvider>;
};
