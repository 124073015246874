import { DropzoneImageInput } from '@components';
import { useRoom } from '../../RoomContext';
import React from 'react'
import { Divider } from '@material-ui/core';
import { useStyles, useImageFile } from '@config';

const RoomHeroImages = () => {
  const classes = useStyles()
  const {
    coverImage,
    setCoverImage,
  } = useRoom()
  const [, setImageFile] = useImageFile()
  
  function onDrop(acceptedFiles, fileRejections, event) {
    if (acceptedFiles[0]) {
      const image = new Image();
      setImageFile(acceptedFiles[0], image)

      image.onload = () => {
        setCoverImage({
          file: acceptedFiles[0],
          url: image.src
        })
      }
    }
  }

  return (
    <>
      <section>
        <div>
          <h2>
            {'Cover'}
            <Divider className={classes['mt0.5']} />
          </h2>
        </div>
        <DropzoneImageInput
          onDrop={onDrop}
          imageURL={coverImage.url}
        />
      </section>
    </>
  )
}

export default RoomHeroImages;
