import { v4 as uuidv4 } from "uuid";
import { Storage, Auth } from "aws-amplify";
import config from "../aws-exports";
import AWS from "aws-sdk";

const S3_BUCKET = process.env.REACT_APP_IMAGES_BUCKET;
const REGION = "us-east-1";

const s3 = new AWS.S3();

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

const putFileOld = async (file) => {
  if (file) {
    const { type: mimeType } = file;
    const key = `${uuidv4()}`;
    const fileForUpload = {
      bucket,
      key,
      region,
      description: "image",
    };

    try {
      await Storage.put(key, file, {
        contentType: mimeType,
      });

      return fileForUpload;
    } catch (err) {
      throw err;
    }
  }
};

const putFile = async (file) => {
  if (file) {
    let credentials = await Auth.currentCredentials();
    const s3 = new AWS.S3({
      credentials: Auth.essentialCredentials(credentials),
    });
    const { type: mimeType } = file;
    const key = `${uuidv4()}`;

    const fileForUpload = {
      S3_BUCKET,
      key,
      REGION,
      description: "image",
    };

    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: key,
    };

    try {
      await s3.putObject(params).promise();

      return fileForUpload;
    } catch (err) {
      console.log(err, err.stack);
      throw err;
    }
  }
};

const deleteFile = async (file) => {
  if (file) {
    let credentials = await Auth.currentCredentials();
    const s3 = new AWS.S3({
      credentials: Auth.essentialCredentials(credentials),
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: file,
    };

    try {
      await s3.deleteObject(params).promise();

      return { message: "deleted file!" };
    } catch (err) {
      console.log(err, err.stack);
      throw err;
    }
  }
};

export { putFile, deleteFile };
