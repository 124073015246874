// Import Swiper React components
import { useStyles, useImageFile } from "@config";
import { Button, Divider, Fab, TextField } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Pagination } from "swiper";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useProperty } from "modules/Property/PropertyContext";
import { useState } from "react";
import { constructImageUrl } from "@components/Images";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "@config/swiper-style.css";

SwiperCore.use([Navigation, Pagination, Thumbs]);

const PropertyListImage = () => {
  const classes = useStyles();
  const [, setImageFile] = useImageFile();
  const { propertyImages, setPropertyImages } = useProperty();
  const [thumbsSwiper, setThumbsSwiper] = useState();
  // store swiper instance
  

  function onAddNewImage(e) {
    
    const allowed = ["image/jpeg", "image/png", "image/jpg"];
    if (e.target.files[0]) {
      if (allowed.includes(e.target.files[0].type)) {
        const image = new Image();
        setImageFile(e.target.files[0], image);
        
        image.onload = () => {
          setPropertyImages([
            ...propertyImages,
            {
              file: e.target.files[0],
              url: image.src,
              bucket: "",
              description: "",
              key: "",
              region: "",
              action: "ADD",
            },
          ]);

          
        };

        
      }
    }
  }

  function onDeleteImage(image, index) {
    const _propertyImages =
      image.action === "ADD"
        ? propertyImages.filter((_, idx) => index !== idx)
        : propertyImages.map((propertyImage, idx) => {
            if (idx === index) {
              return {
                ...propertyImage,
                action: "DELETE",
              };
            }

            return propertyImage;
          });

    setPropertyImages(_propertyImages);
  }

  function onChangeInput(e, idx) {
    const _propertyImages = propertyImages.map((propertyImage, index) => {
      if (index === idx) {
        return {
          ...propertyImage,
          description: e.target.value,
        };
      }

      return propertyImage;
    });

    setPropertyImages(_propertyImages);
  }

  return (
    <section>
      <div>
        <h2>
          {"Property Images"}
          <Divider className={classes["mt0.5"]} />
        </h2>
      </div>

      <div style={{ width: "100%" }}>
        <input
          accept={"image/jpeg, image/jpg, image/png"}
          style={{ display: "none", width: "100%", height: "50px" }}
          id="button-file"
          type="file"
          onChange={onAddNewImage}
        />
        <label htmlFor="button-file">
          <Button
            style={{ width: "100%", height: "50px" }}
            type={"button"}
            variant="contained"
            color="primary"
            disabled={false}
            component="span"
          >
            {"Add An Image"}
          </Button>
        </label>
      </div>

      <section className={classes["mt2"]}>
        {thumbsSwiper && (
          <Swiper
            observer
            navigation
            pagination={{ clickable: true, type: "bullets" }}
            thumbs={{ swiper: thumbsSwiper }}
            slidesPerView={1}
            onSwiper={(swiper) => {}}
            onSlideChange={() => {
              // console.log("slide change")
            }}
            tag="section"
            wrapperTag="ul"
            style={{
              paddingLeft: 0,
            }}
          >
            {propertyImages &&
              propertyImages.map(
                (propertyImage, index) =>
                  propertyImage.action !== "DELETE" && (
                    <SwiperSlide
                      key={index.toString()}
                      tag="li"
                      style={{
                        listStyle: "none",
                      }}
                    >
                      <section>
                        <section className={classes.mt1}>
                          <TextField
                            className={[classes.textField].join(" ")}
                            name="shortDescription"
                            label="Short Description"
                            value={propertyImage.description}
                            variant="outlined"
                            onChange={(e) => onChangeInput(e, index)}
                          />
                        </section>

                        <section className={classes.mt1}>
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                position: "absolute",
                                top: "3px",
                                left: "3px",
                              }}
                            >
                              <Fab color="secondary" aria-label="edit">
                                <DeleteOutlineIcon
                                  fontSize={"large"}
                                  onClick={() =>
                                    onDeleteImage(propertyImage, index)
                                  }
                                />
                              </Fab>
                            </div>
                          </div>

                          <img
                            width="100%"
                            height={640}
                            src={propertyImage.url}
                            alt={""}
                          />
                        </section>
                      </section>
                    </SwiperSlide>
                  )
              )}
          </Swiper>
        )}

        <Swiper
          onSwiper={(swiper) => setThumbsSwiper(swiper)}
          observer
          watchSlidesVisibility
          watchSlidesProgress
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            425: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
        >
          {propertyImages &&
            propertyImages.map(
              (propertyImage, index) =>
                propertyImage.action !== "DELETE" && (
                  <SwiperSlide key={index.toString()}>
                    <img
                      width={'100%'}
                      height={200}
                      src={propertyImage.url}
                      alt={"Al1"}
                    />
                  </SwiperSlide>
                )
            )}
        </Swiper>
      </section>
    </section>
  );
};

export default PropertyListImage;
