import React, { useEffect, useState } from "react";
import clsx from "clsx";
// import { useStyles } from '@components/SideMenu/styles';
import {
  Divider,
  Grid,
  Paper,
  Fab,
  Tooltip,
  Backdrop,
  Button,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  format,
  formatDistance,
  formatRelative,
  setDate,
  subDays,
} from "date-fns";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import ListItemText from "@material-ui/core/ListItemText";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useStyles } from "@config";
import { useReservationIntoStyles } from "./styles";
import { useReservation } from "../ReservationContext";
import { FormLabel } from "@material-ui/core";
import { makeCurrency } from "@components/Currency";
import { formatPhoneNumber } from "@components/PhoneNumber";
import { copyTextToClipboard } from "@components/CopyToClipboard";
import { AlertDialog, Select } from "@components";
import { useHistory } from "react-router-dom";
import ReservationUserDoc from "../ReservationUserDoc";
import ReservationListService from "../ReservationList/ReservationListService";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useLayout } from "@components/Layout";

const stripeUrl = (liveMode, type, id) =>
  `https://dashboard.stripe.com${liveMode ? "/" : "/test/"}${type}/${id}`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function StringToDate(date) {
  return moment(date.substring(0, date.length - 1)).format("M/D/YYYY");
}

const RevervationDetailInfo = ({ match }) => {
  const {
    fetchReservation,
    fetchAvailableDatesByRoom,
    fetchPaymentSchedule,
    isLoading,
    setIsLoading,
  } = useReservation();
  const classes = useStyles();
  const reservationClasses = useReservationIntoStyles();
  const [reservation, setReservation] = useState();
  const [details, setDetails] = useState();
  const [checkoutInfo, setCheckoutInfo] = useState();
  const [paymentPlan, setPaymentPlan] = useState();
  const [stripeData, setStripeData] = useState();
  const [showReservationDoc, setShowReservationDoc] = useState(false);
  const [userId, setUserId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [statusSelected, setStatusSelected] = useState();
  const [bookedDates, setBookedDates] = useState();
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);

  const [checkOutDate, setCheckOutDate] = React.useState();
  const [focused, setFocused] = React.useState();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [dataLayout] = useLayout();
  let history = useHistory();
  const colors = ["#853a2f", "#85652f", "#442f85", "#2f5a85"];
  Array.prototype.sum = function (prop) {
    var total = 0;
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop];
    }
    return total;
  };

  function onCancel() {
    history.push("/reservations");
  }
  function createSubDateRanges() {
    // let test = bookedDates.filter((a,b)=>{
    //   console.log(a)
    //   console.log(b)
    //   return(a.checkIn)
    // }, date)
    // console.log(test)
  }

  function isDateDisabled(date) {
    let disabled = false;
    let test = bookedDates.map((dates, idx) => {
      // return moment(date).isBetween(dates.checkIn, dates.checkOut);
      if (date.isBetween(dates.checkIn, dates.checkOut)) {
        disabled = true;
      }
    });

    return disabled;
  }

  function getHolidayColor(day) {
    let dayColor = "white";
    bookedDates.map((dates, idx) => {
      if (day.isBetween(dates.checkIn, dates.checkOut)) {
        dayColor = dates.color;
      }
    });
    return dayColor;
  }

  function renderDay(day) {
    const holidayColor = getHolidayColor(day);

    return (
      <div
        style={{
          backgroundColor: holidayColor ? holidayColor : "white",
          height: "100%",
          padding: "7px",
          color: "black",
        }}
      >
        <span>{moment(day).format("D")}</span>
      </div>
    );
  }

  function onEdit() {
    setEditMode(true);
  }

  function saveContent() {
    let options = {
      roomId: reservation.Room.id,
      startDate: reservation.checkInDate,
      endDate: checkOutDate,
      nights: moment(checkOutDate).diff(reservation.checkInDate, "days") - 1,
    };

    //prepare payment plan to update stripe....but not doing that now
    let runIt = async (options) => {
      let test = await fetchPaymentSchedule(options);
      let data = JSON.parse(test);

      setPaymentPlan(data.paymentSchedule);

      //update checkout date
      const reservationListService = new ReservationListService();
      let newDate = `${new moment(checkOutDate).format("YYYY-MM-DD")}Z`;

      setIsLoading(true);
      try {
        let resStatus = await reservationListService.updateEndDate(
          reservation.id,
          newDate
        );

        setIsLoading(false);

        history.push("/reservations");
      } catch (e) {
        setErrorOpen(true);
      }
    };

    runIt(options);

    // history.push("/reservations");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getData = async () => {
    const data = await fetchReservation(match.params.id);

    setReservation(data);
    const _bookedDatesData = await fetchAvailableDatesByRoom(data.Room.id);

    const _bookedDates = _bookedDatesData.map((x, idx) => {
      return {
        checkIn: new Date(x.checkInDate),
        checkOut: new Date(x.checkOutDate),
        color: colors[idx],
      };
    });

    setCheckOutDate(moment(data.checkOutDate).add(1, "days"));
    setBookedDates(_bookedDates);
    createSubDateRanges();
    setDetails(JSON.parse(data.reservationDetails));
    setCheckoutInfo(JSON.parse(data.checkoutDetails));
    setPaymentPlan(JSON.parse(data.paymentPlan));
    setStripeData(JSON.parse(data.paymentProcessorData));
    setStatusSelected(data.status);
  };

  useEffect(() => {
    (async () => {
      if (match.params && match.params.id) {
        getData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params]);

  return (
    <Grid container spacing={1}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={1} style={{ marginBottom: "1em" }}>
        <Grid item xs={12}>
          <List
            className={clsx(
              dataLayout.openDrawer && classes.drawerPaperFormActionOpen,
              !dataLayout.openDrawer && classes.drawerPaperFormActionClose
            )}
          >
            {editMode && (
              <Fab
                size="small"
                color="primary"
                aria-label="save"
                onClick={() => saveContent()}
              >
                <SaveIcon />
              </Fab>
            )}
            {!editMode && (
              <Fab
                size="small"
                color="primary"
                aria-label="edit"
                style={{ marginTop: "0.5em" }}
                onClick={onEdit}
              >
                <EditIcon />
              </Fab>
            )}
            <Fab
              size="small"
              aria-label="cancel"
              style={{ marginTop: "0.5em" }}
              onClick={onCancel}
            >
              <CloseIcon />
            </Fab>
          </List>
        </Grid>
      </Grid>
      {details && reservation && (
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <h3>{`Reservation Info`}</h3>
              </Grid>

              {/* <Grid item xs={12} sm={3}>
              <Button variant="outlined">Extend Stay</Button>
              <Button variant="outlined">Cancel</Button>
              </Grid> */}
            </Grid>
            <Divider />
            <Grid container spacing={1} style={{ marginTop: "1em" }}>
              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Date Booked</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>
                      {new Date(reservation.createdAt).toLocaleDateString(
                        "en-US"
                      )}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Reservation ID</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>{reservation.id}</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Confirmation Number</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>{reservation.friendlyId}</span>
                  </div>
                </div>
              </Grid>
              {!editMode && (
                <Grid item xs={12} sm={3}>
                  <div className={reservationClasses.itemDetail}>
                    <div>
                      <FormLabel>Status</FormLabel>
                    </div>

                    <div style={{ paddingTop: "0.4em" }}>
                      <span>{reservation.status}</span>
                    </div>
                  </div>
                </Grid>
              )}
              {editMode && (
                <Grid item xs={12} sm={3}>
                  <Select
                    name="bedType"
                    label={"Status"}
                    disabled={reservation.status === "CANCELED"}
                    value={reservation.status}
                    values={[
                      {
                        id: "PENDING_APPROVAL",
                        name: "Pending Approval",
                      },
                      {
                        id: "PENDING_PAYMENT",
                        name: "Pending Payment",
                      },
                      {
                        id: "APPROVED",
                        name: "Approved",
                      },
                      {
                        id: "CANCELED",
                        name: "Canceled",
                      },
                    ]}
                    onChange={async (value) => {
                      const reservationListService =
                        new ReservationListService();
                      setStatusSelected(value.target.value);
                      setIsLoading(true);
                      let resStatus = await reservationListService.updateStatus(
                        reservation.id,
                        value.target.value
                      );

                      if (resStatus.errors) {
                        setErrorOpen(true);
                      } else {
                        setOpen(true);
                        getData();
                      }
                      setIsLoading(false);

                      // history.push("/reservations");
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1} style={{ marginTop: "1em" }}>
              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Room</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>{`${reservation.Room.Property.shortDescription} - ${reservation.Room.title}`}</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Check In</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>{StringToDate(reservation.checkInDate)}</span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Check Out</FormLabel>
                  </div>

                  {!editMode && (
                    <div style={{ paddingTop: "0.4em" }}>
                      <span>{StringToDate(reservation.checkOutDate)}</span>
                    </div>
                  )}
                  {editMode && (
                    <div style={{ paddingTop: "0.4em" }}>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          value={date}
                          format="M/d/yyyy"
                          onChange={changeDate}
                          shouldDisableDate={isDateDisabled}
                          disablePast={true}
                        />
                      </MuiPickersUtilsProvider> */}
                      <SingleDatePicker
                        date={checkOutDate}
                        onDateChange={(date) => {
                          setCheckOutDate(date);
                        }}
                        focused={focused}
                        onFocusChange={({ focused }) => setFocused(focused)}
                        id="date"
                        appendToBody={true}
                        renderDayContents={renderDay}
                        //isDayBlocked={(date) => isDateDisabled(date)}
                        isDayHighlighted={(date) => isDateDisabled(date)}
                      />
                    </div>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Nights</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>{details.nights}</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

      {checkoutInfo && (
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            <h3>{`Customer Information`}</h3>
            <Divider />

            <Grid container spacing={1} style={{ marginTop: "1em" }}>
              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Customer Name</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>{`${checkoutInfo.firstName} ${checkoutInfo.lastName}`}</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Email</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>
                      <a href={`mailto:${checkoutInfo.email}`}>
                        {checkoutInfo.email}
                      </a>
                      <Tooltip title="Copy email address">
                        <AssignmentIcon
                          style={{ paddingTop: "0.4em", cursor: "pointer" }}
                          onClick={(x) => {
                            copyTextToClipboard(checkoutInfo.email);
                          }}
                        ></AssignmentIcon>
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Phone</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>{formatPhoneNumber(checkoutInfo.phone)}</span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Referral</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>{reservation.referral}</span>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: "1em" }}>
              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Address</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <span>{checkoutInfo.address}</span>
                  </div>
                  <div>
                    {checkoutInfo.city} {checkoutInfo.state}, {checkoutInfo.zip}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowReservationDoc(true);
                    setUserId(reservation.User.id);
                  }}
                >
                  {`View Documents`}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      {paymentPlan && (
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            <h3>{`Payment Schedule`}</h3>
            <Divider />

            <Grid container spacing={1} style={{ marginTop: "1em" }}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Items</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentPlan.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ verticalAlign: "top" }}
                      >
                        {moment(row.date).format("M/D/yyyy")}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {makeCurrency(row.amount)}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        <List dense>
                          {row.items.map((item) => {
                            return (
                              <ListItem>
                                <ListItemText
                                  primary={makeCurrency(item.amount)}
                                  secondary={item.description}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Grand Total:
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {makeCurrency(paymentPlan.sum("amount"))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Paper>
        </Grid>
      )}
      {stripeData && (
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            <h3>{`Stripe Information`}</h3>
            <Divider />

            <Grid container spacing={1} style={{ marginTop: "1em" }}>
              <Grid item xs={12} sm={3}>
                <div className={reservationClasses.itemDetail}>
                  <div>
                    <FormLabel>Customer Id</FormLabel>
                  </div>

                  <div style={{ paddingTop: "0.4em" }}>
                    <a
                      target="_blank"
                      href={`${stripeUrl(
                        stripeData.customer.livemode,
                        "customers",
                        stripeData.customer.id
                      )}`}
                    >
                      {stripeData.customer.id}
                    </a>
                  </div>
                </div>
              </Grid>
              {stripeData.subscription && (
                <Grid item xs={12} sm={3}>
                  <div className={reservationClasses.itemDetail}>
                    <div>
                      <FormLabel>Subscription Id</FormLabel>
                    </div>

                    <div style={{ paddingTop: "0.4em" }}>
                      <a
                        target="_blank"
                        href={`${stripeUrl(
                          stripeData.subscription.livemode,
                          "subscriptions",
                          stripeData.subscription.id
                        )}`}
                      >
                        {stripeData.subscription.id}
                      </a>
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      )}
      {/* {checkoutInfo && (
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <FormLabel
                  style={{
                    color: "red",
                  }}
                >
                  reservation
                </FormLabel>
                 <Grid container spacing={1} style={{ marginTop: "1em" }}>
                  <Grid item xs={12} sm={3}>
                    <pre>{JSON.stringify(reservation, null, 2)}</pre>
                  </Grid>
                </Grid> 
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )} */}
      <AlertDialog
        open={showReservationDoc}
        title={"Documents"}
        handleClose={() => {
          setShowReservationDoc(false);
          setUserId("");
        }}
      >
        <ReservationUserDoc userId={userId} />
      </AlertDialog>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Reservation status updated successfully!
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Error encountered.
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default RevervationDetailInfo;
