import React, { useEffect, useState } from "react";
import { listContents, getContent } from "@data/queries";
import { updateContent } from "@data/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { useLayout } from "@components/Layout";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import draftToMarkdown from "draftjs-to-markdown";
import { mdToDraftjs, draftjsToMd } from "draftjs-md-converter";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./content.css";
import ReactMarkdown from "react-markdown";
import { Button, Grid, Fab, List } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
import { useStyles } from "@config";

const ContentForm = ({ match }) => {
  const classes = useStyles();
  const [dataLayout] = useLayout();
  const history = useHistory();
  const [content, setContent] = useState();
  const [, setDataLayout] = useLayout();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const fetchContent = async () => {
    try {
      const {
        data: { getContent: _content },
      } = await API.graphql(
        graphqlOperation(getContent, { id: match.params.id })
      );

      return _content;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const saveContent = async () => {
    delete content.createdAt;
    delete content.updatedAt;
    content.text = draftjsToMd(convertToRaw(editorState.getCurrentContent()));

    try {
      const { data } = await API.graphql(
        graphqlOperation(updateContent, { input: content })
      );
      history.push(`/content`);
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const onEditorStateChange = (_editorState) => {
    setEditorState(_editorState);
  };

  const onCancel = () => history.push("/content");

  const getData = async () => {
    let _content = await fetchContent();
    
    setContent(_content);
    const rawData = mdToDraftjs(_content.text);
    const newContent = convertFromRaw(rawData);
    setEditorState(EditorState.createWithContent(newContent));
  };

  useEffect(() => {
    setDataLayout((datalayout) => ({
      ...datalayout,
      drawerTitle: "Content",
    }));

    getData();
  }, []);

  // return <div>{content && <pre>{JSON.stringify(content, null, 2)}</pre>}</div>;
  //
  return (
    <div>
      <Grid container spacing={1} style={{ marginBottom: "1em" }}>
        <Grid item xs={12}>
          <List
            className={clsx(
              dataLayout.openDrawer && classes.drawerPaperFormActionOpen,
              !dataLayout.openDrawer && classes.drawerPaperFormActionClose
            )}
          >
            <Fab size="small" color="primary" aria-label="save" onClick={()=>saveContent()}>
              <SaveIcon />
            </Fab>

            <Fab
              size="small"
              aria-label="cancel"
              style={{ marginTop: "0.5em" }}
              onClick={onCancel}
            >
              <CloseIcon />
            </Fab>
          </List>
        </Grid>
      </Grid>
      <Editor
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "history",
          ],
        }}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="medbed-editor"
        onEditorStateChange={onEditorStateChange}
      />
      {/* <ReactMarkdown>{draftjsToMd(convertToRaw(editorState.getCurrentContent()))}</ReactMarkdown>  */}
      {/* <textarea
        disabled
        value={draftToMarkdown(convertToRaw(editorState.getCurrentContent()))}
      /> */}
    </div>
  );
};

export default ContentForm;
