  // const awsmobile = {
  //   aws_project_region: "us-east-1",
  //   aws_cognito_identity_pool_id:
  //     "us-east-1:630af7b1-724b-42f7-82fb-e1a00ead9763",
  //   aws_cognito_region: "us-east-1",
  //   aws_user_pools_id: "us-east-1_ylxmcg8ok",
  //   aws_user_pools_web_client_id: "4nvilmeggg0oggi5s1fj0cfbm",
  //   oauth: {},
  //   aws_cloud_logic_custom: [
  //     {
  //       name: "AdminQueries",
  //       endpoint:
  //         "https://01bmrcpjd3.execute-api.us-east-1.amazonaws.com/develop",
  //       region: "us-east-1",
  //     },
  //   ],
  //   aws_appsync_graphqlEndpoint:
  //     "https://kd7nqy4lfzezldiglecm56pdqy.appsync-api.us-east-1.amazonaws.com/graphql",
  //   aws_appsync_region: "us-east-1",
  //   aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  //   aws_appsync_apiKey: "da2-5uc6gb2cgzbftf7k7hd46gkccu",
  //   aws_user_files_s3_bucket: "findamedbedstoragebk81951-develop",
  //   aws_user_files_s3_bucket_region: "us-east-1",
  //   aws_content_delivery_bucket:
  //     "findamedbed-20210628113948-hostingbucket-develop",
  //   aws_content_delivery_bucket_region: "us-east-1",
  //   aws_content_delivery_url: "https://d3tj0jogd7hntp.cloudfront.net",
  // };


const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:92b1af5b-a427-4e4b-8ca0-bb3186408e32",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_N2aNPJ0IG",
  aws_user_pools_web_client_id: "4aa11u2nikic5dje6bcemsn4rd",
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: "AdminQueries",
      endpoint:
        "https://hy829vmh1e.execute-api.us-east-1.amazonaws.com/production",
      region: "us-east-1",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://3ec2tdzxzvhdblnq67agindc34.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "da2-zqqhqsctdnfw7ep3cz52yx4g4y",
  aws_user_files_s3_bucket: "findamedbedstoragebk65414-production",
  aws_user_files_s3_bucket_region: "us-east-1",
  aws_content_delivery_bucket:
    "findamedbed-20210628113948-hostingbucket-production",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_url: "https://d3gasnk82v8pa9.cloudfront.net",
};


// //this is for staging

// // /* eslint-disable */
// // // WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// const ENV = process.env.REACT_APP_ENV || "DEVELOP";
// console.log(ENV)
// let awsmobile = {};

// if (ENV === "DEVELOP") {
//   awsmobile = {
//     aws_project_region: "us-east-1",
//     aws_cognito_identity_pool_id:
//       "us-east-1:630af7b1-724b-42f7-82fb-e1a00ead9763",
//     aws_cognito_region: "us-east-1",
//     aws_user_pools_id: "us-east-1_ylxmcg8ok",
//     aws_user_pools_web_client_id: "4nvilmeggg0oggi5s1fj0cfbm",
//     oauth: {},
//     aws_cloud_logic_custom: [
//       {
//         name: "AdminQueries",
//         endpoint:
//           "https://01bmrcpjd3.execute-api.us-east-1.amazonaws.com/develop",
//         region: "us-east-1",
//       },
//     ],
//     aws_appsync_graphqlEndpoint:
//       "https://kd7nqy4lfzezldiglecm56pdqy.appsync-api.us-east-1.amazonaws.com/graphql",
//     aws_appsync_region: "us-east-1",
//     aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
//     aws_appsync_apiKey: "da2-5uc6gb2cgzbftf7k7hd46gkccu",
//     aws_user_files_s3_bucket: "findamedbedstoragebk81951-develop",
//     aws_user_files_s3_bucket_region: "us-east-1",
//     aws_content_delivery_bucket:
//       "findamedbed-20210628113948-hostingbucket-develop",
//     aws_content_delivery_bucket_region: "us-east-1",
//     aws_content_delivery_url: "https://d3tj0jogd7hntp.cloudfront.net",
//   };
// }

// if (ENV === "PRODUCTION") {
//   awsmobile = {
//     aws_project_region: "us-east-1",
//     aws_cognito_identity_pool_id:
//       "us-east-1:92b1af5b-a427-4e4b-8ca0-bb3186408e32",
//     aws_cognito_region: "us-east-1",
//     aws_user_pools_id: "us-east-1_N2aNPJ0IG",
//     aws_user_pools_web_client_id: "4aa11u2nikic5dje6bcemsn4rd",
//     oauth: {},
//     aws_cloud_logic_custom: [
//       {
//         name: "AdminQueries",
//         endpoint:
//           "https://hy829vmh1e.execute-api.us-east-1.amazonaws.com/production",
//         region: "us-east-1",
//       },
//     ],
//     aws_appsync_graphqlEndpoint:
//       "https://3ec2tdzxzvhdblnq67agindc34.appsync-api.us-east-1.amazonaws.com/graphql",
//     aws_appsync_region: "us-east-1",
//     aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
//     aws_appsync_apiKey: "da2-zqqhqsctdnfw7ep3cz52yx4g4y",
//     aws_user_files_s3_bucket: "findamedbedstoragebk65414-production",
//     aws_user_files_s3_bucket_region: "us-east-1",
//     aws_content_delivery_bucket:
//       "findamedbed-20210628113948-hostingbucket-production",
//     aws_content_delivery_bucket_region: "us-east-1",
//     aws_content_delivery_url: "https://d3gasnk82v8pa9.cloudfront.net",
//   };
// }

export default awsmobile;
