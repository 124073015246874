export const sortString = (a, b) => {
  if (a.toLowerCase() < b.toLowerCase()) return -1;

  if (a.toLowerCase() > b.toLowerCase()) return 1;
  return 0;
};

export const sortDate = (a, b) => {
  return new Date(b) - new Date(a);
};

export const sortingBool = (a, b) => {
  return a === b
    ? 0
    : a
    ? -1
    : 1;
};
