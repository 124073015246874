import React, { useEffect, useState } from "react";
// import clsx from "clsx";
import { Backdrop, CircularProgress, Fab } from "@material-ui/core";
import { useStyles } from "@config";
import { useReservation } from "../ReservationContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Storage } from "aws-amplify";
import GetAppIcon from "@material-ui/icons/GetApp";

const ReservationUserDoc = ({ userId }) => {
  const { fetchDocumentUsers, isLoading, setIsLoading } = useReservation();
  const classes = useStyles();
  const [documentImages, setDocumentImages] = useState();
  const [documents, setDocuments] = useState();

  useEffect(() => {
    (async () => {
      if (userId && userId.length > 0) {
        setIsLoading(true);
        const data = await fetchDocumentUsers(userId);
        setDocuments(data.documents);
        const images = [];
        for (const doc of data.documents) {
          if (doc.key) {
            const imageURL = await Storage.get(doc.key);
            
            images.push(imageURL);
          }
        }
        setDocumentImages(images);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  async function onDownoloadImage(url) {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = url;
    a.download = url;
    a.click();
  }

  return (
    <section className={classes["mt2"]}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <table>
        <tr>
          <td>Document Name</td>
          <td>Actions</td>
        </tr>
        {documents && documents.map((doc) => (
          <tr>
            <td>{doc.key}</td>
            <td>Download | View</td>
          </tr>
        ))}
      </table> */}
      <Swiper
        observer
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => {}}
        onSlideChange={() => {
          // console.log("slide change")
        }}
      >
        {documentImages &&
          documentImages.map((documentImage, index) => (
            <SwiperSlide key={index.toString()}>
              <section>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "-10px",
                      left: "3px",
                    }}
                  >
                    <Fab color="secondary" aria-label="edit">
                      <GetAppIcon
                        fontSize={"large"}
                        onClick={() => onDownoloadImage(documentImage)}
                      />
                    </Fab>
                  </div>
                </div>
                <section
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={classes.mt1}
                >
                  <img
                    width="500"
                    height={"500"}
                    src={documentImage}
                    alt={""}
                  />
                </section>
              </section>
            </SwiperSlide>
          ))}
      </Swiper>
    </section>
  );
};

export default ReservationUserDoc;
