/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const hospitalsNearLocations = /* GraphQL */ `
  query HospitalsNearLocations($lat: Float, $lng: Float, $radius: String) {
    hospitalsNearLocations(lat: $lat, lng: $lng, radius: $radius)
  }
`;
export const getPaymenSchedule = /* GraphQL */ `
  query GetPaymenSchedule(
    $setUpFee: Float
    $totalAmount: Float
    $numberPayments: Int
  ) {
    getPaymenSchedule(
      setUpFee: $setUpFee
      totalAmount: $totalAmount
      numberPayments: $numberPayments
    )
  }
`;
export const roomReserver = /* GraphQL */ `
  query RoomReserver(
    $roomId: String
    $nights: Int
    $startDate: String
    $endDate: String
    $calendarIds: [String]
    $reserve: Boolean
  ) {
    roomReserver(
      roomId: $roomId
      nights: $nights
      startDate: $startDate
      endDate: $endDate
      calendarIds: $calendarIds
      reserve: $reserve
    )
  }
`;
export const stripeSession = /* GraphQL */ `
  query StripeSession($customerId: String, $email: String) {
    stripeSession(customerId: $customerId, email: $email)
  }
`;
export const stripeSubscribe = /* GraphQL */ `
  query StripeSubscribe($reservationId: String) {
    stripeSubscribe(reservationId: $reservationId)
  }
`;
export const stripeInitialize = /* GraphQL */ `
  query StripeInitialize(
    $customerId: String
    $email: String
    $name: String
    $checkIn: String
    $checkOut: String
    $cardInfo: CardInfo
    $trialEnd: String
    $paymentSchedule: [PaymentSchedule]
    $propertyShort: String
  ) {
    stripeInitialize(
      customerId: $customerId
      email: $email
      name: $name
      checkIn: $checkIn
      checkOut: $checkOut
      cardInfo: $cardInfo
      trialEnd: $trialEnd
      paymentSchedule: $paymentSchedule
      propertyShort: $propertyShort
    )
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      address1
      address2
      city
      state
      postalCode
      email
      phone
      terms
      documents {
        description
        bucket
        region
        key
      }
      avatar {
        description
        bucket
        region
        key
      }
      username
      paymentProcessorId
      createdAt
      updatedAt
      Reservation {
        items {
          id
          friendlyId
          createDatetime
          paymentProcessorData
          paymentPlan
          reservationDetails
          checkoutDetails
          checkInDate
          checkOutDate
          totalAmount
          referral
          status
          roomId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      Review {
        items {
          id
          rating
          comment
          featured
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        postalCode
        email
        phone
        terms
        documents {
          description
          bucket
          region
          key
        }
        avatar {
          description
          bucket
          region
          key
        }
        username
        paymentProcessorId
        createdAt
        updatedAt
        Reservation {
          nextToken
        }
        Review {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      baths
      beds
      title
      address
      city
      state
      postalCode
      description
      shortDescription
      instructions
      type
      heroImage
      emailAttachment {
        description
        bucket
        region
        key
      }
      images
      createdAt
      updatedAt
      Room {
        items {
          id
          title
          description
          hasBathroom
          heroImage
          images
          price
          discount
          sqft
          bedType
          fees
          emailTemplate
          createdAt
          updatedAt
        }
        nextToken
      }
      Amenities {
        items {
          id
          sortOrder
          tooltip
          description
          icon
          grouping
          createdAt
          updatedAt
        }
        nextToken
      }
      Review {
        items {
          id
          rating
          comment
          featured
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPropertys = /* GraphQL */ `
  query ListPropertys(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRoom = /* GraphQL */ `
  query GetRoom($id: ID!) {
    getRoom(id: $id) {
      id
      title
      description
      hasBathroom
      heroImage
      images
      price
      discount
      active
      prices {
        id
        title
        description
        stripePriceId
        amount
        displayAmount
        type
        amountType
        recurrenceType
        incrementType
        increment
      }
      sqft
      bedType
      fees
      emailTemplate
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
      Reservation {
        items {
          id
          friendlyId
          createDatetime
          paymentProcessorData
          paymentPlan
          reservationDetails
          checkoutDetails
          checkInDate
          checkOutDate
          totalAmount
          referral
          status
          roomId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      Amenities {
        items {
          id
          sortOrder
          tooltip
          description
          icon
          grouping
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listRooms = /* GraphQL */ `
  query ListRooms(
    $filter: ModelRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        hasBathroom
        heroImage
        images
        price
        discount
        active
        prices {
          id
          title
          description
          stripePriceId
          amount
          displayAmount
          type
          amountType
          recurrenceType
          incrementType
          increment
        }
        sqft
        bedType
        fees
        emailTemplate
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
        Reservation {
          nextToken
        }
        Amenities {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($id: ID!) {
    getReservation(id: $id) {
      id
      friendlyId
      createDatetime
      paymentProcessorData
      paymentPlan
      reservationDetails
      checkoutDetails
      checkInDate
      checkOutDate
      totalAmount
      referral
      status
      roomId
      userId
      User {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        postalCode
        email
        phone
        terms
        documents {
          description
          bucket
          region
          key
        }
        avatar {
          description
          bucket
          region
          key
        }
        username
        paymentProcessorId
        createdAt
        updatedAt
        Reservation {
          nextToken
        }
        Review {
          nextToken
        }
      }
      createdAt
      updatedAt
      Room {
        id
        title
        description
        hasBathroom
        heroImage
        images
        price
        discount
        prices {
          id
          title
          description
          stripePriceId
          amount
          displayAmount
          type
          amountType
          recurrenceType
          incrementType
          increment
        }
        sqft
        bedType
        fees
        emailTemplate
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
        Reservation {
          nextToken
        }
        Amenities {
          nextToken
        }
      }
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        friendlyId
        createDatetime
        paymentProcessorData
        paymentPlan
        reservationDetails
        checkoutDetails
        checkInDate
        checkOutDate
        totalAmount
        referral
        status
        roomId
        userId
        User {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          postalCode
          email
          phone
          terms
          username
          paymentProcessorId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        Room {
          id
          title
          description
          hasBathroom
          heroImage
          images
          price
          discount
          sqft
          bedType
          fees
          emailTemplate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getConfig = /* GraphQL */ `
  query GetConfig($id: ID!) {
    getConfig(id: $id) {
      id
      cleaningFee
      setUpFee
      setUpPercent
      createdAt
      updatedAt
    }
  }
`;
export const listConfigs = /* GraphQL */ `
  query ListConfigs(
    $filter: ModelConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cleaningFee
        setUpFee
        setUpPercent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAmenity = /* GraphQL */ `
  query GetAmenity($id: ID!) {
    getAmenity(id: $id) {
      id
      sortOrder
      tooltip
      description
      icon
      grouping
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
      Room {
        id
        title
        description
        hasBathroom
        heroImage
        images
        price
        discount
        prices {
          id
          title
          description
          stripePriceId
          amount
          displayAmount
          type
          amountType
          recurrenceType
          incrementType
          increment
        }
        sqft
        bedType
        fees
        emailTemplate
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
        Reservation {
          nextToken
        }
        Amenities {
          nextToken
        }
      }
    }
  }
`;
export const listAmenitys = /* GraphQL */ `
  query ListAmenitys(
    $filter: ModelAmenityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmenitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sortOrder
        tooltip
        description
        icon
        grouping
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
        Room {
          id
          title
          description
          hasBathroom
          heroImage
          images
          price
          discount
          sqft
          bedType
          fees
          emailTemplate
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getContent = /* GraphQL */ `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      contentTitle
      text
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contentTitle
        text
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      rating
      comment
      featured
      User {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        postalCode
        email
        phone
        terms
        documents {
          description
          bucket
          region
          key
        }
        avatar {
          description
          bucket
          region
          key
        }
        username
        paymentProcessorId
        createdAt
        updatedAt
        Reservation {
          nextToken
        }
        Review {
          nextToken
        }
      }
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rating
        comment
        featured
        User {
          id
          firstName
          lastName
          address1
          address2
          city
          state
          postalCode
          email
          phone
          terms
          username
          paymentProcessorId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
