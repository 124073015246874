import { listRooms, getRoom } from "@data/queries";
import {
  updateRoom as gqUpdateRoom,
  createRoom as gqCreateRoom,
} from "@data/mutations";
import { putFile, deleteFile } from "@config";
import { API, graphqlOperation } from "aws-amplify";
import { PropertyService } from "@modules/Property";

export default class RoomService {
  async getList() {
    try {
      const {
        data: {
          listRooms: { items },
        },
      } = await API.graphql(graphqlOperation(listRooms));

      return items;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getPropertyList() {
    try {
      const propertyService = new PropertyService();

      const properties = await propertyService.getList();

      return properties;
    } catch (err) {
      throw err;
    }
  }

  async getRoom(roomId) {
    try {
      const {
        data: { getRoom: data },
      } = await API.graphql(graphqlOperation(getRoom, { id: roomId }));

      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async insertRoom(room) {
    try {
      const {
        data: { gqCreateRoom: data },
      } = await API.graphql(graphqlOperation(gqCreateRoom, { input: room }));

      return data;
    } catch (err) {
      throw err;
    }
  }

  async updateRoom(room) {
    try {
      const { data } = await API.graphql(
        graphqlOperation(gqUpdateRoom, { input: room })
      );

      return data;
    } catch (err) {
      throw err;
    }
  }

  async parseRoomImages(imagesFile) {
    const _propertyImages = [];
    if (imagesFile.length > 0) {
      for (const imageFile of imagesFile) {
        if (imageFile.action === "ADD") {
          const imageProperty = await putFile(imageFile.file);
          _propertyImages.push(imageProperty.key);
        }

        if (imageFile.action === "KEEP") {
          _propertyImages.push(imageFile.key);
        }

        if (imageFile.action === "DELETE") {
          const imageProperty = await deleteFile(imageFile.key);
        }
      }
    }

    return _propertyImages.filter(
      (propertyImage) => propertyImage.action !== "DELETE"
    );
  }
}
