import { createContext, useReducer, useMemo, useContext, useEffect } from "react";
import { initialState, userReducer } from "./context.store";
import UserService from "./UserService";
import { useLayout } from "@components/Layout";
import { putFile } from "@config";
import { Storage } from "aws-amplify";

export const UserContext = createContext(initialState);

UserContext.displayName = "UserContext";

export const UserProvider = (props) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const setUser = (user) =>
    dispatch({ type: "SET_USER", user });

  const setUsers = (users) =>
    dispatch({ type: "SET_USERS", users });

  const setIsLoading = (isLoading) =>
    dispatch({ type: "SET_IS_LOADING", isLoading });

  const setAvatarImage = (avatarImage) =>
    dispatch({ type: "SET_AVATAR_IMAGE", avatarImage });

  const setDocumentImages = (documentImages) =>
    dispatch({ type: "SET_DOCUMENT_IMAGES", documentImages });

  const cleanRoomForm = () => dispatch({ type: "CLEAN_ROOM_FORM" });

  const fetchUsers = async () => {
    const userService = new UserService();

    try {
      const data = await userService.getList();
      console.log(data)
      return data;
    } catch (err) {}
  };

  const fetchUser = async (userId) => {
    const userService = new UserService();

    try {
      const data = await userService.getUser(userId);

      if (data.documents) {
        const _documentImages = [];
        for (const document of data.documents) {
          const imageURL = await Storage.get(document.key);

          _documentImages.push({
            file: null,
            url: imageURL,
            bucket: document.bucket,
            description: document.description,
            key: document.key,
            region: document.region,
            action: "KEEP",
          });
        }

        setDocumentImages(_documentImages);
      }

      return data;
    } catch (err) {}
  };

  const insertUser = async (user, avatar, documentsFile) => {
    const userService = new UserService();

    try {
      if (avatar.file) {
        const heroImage = await putFile(avatar.file);
        user.avatar = heroImage;
      }

      if (user.avatar && user.avatar.key.length === 0) {
        user.avatar = null;
      }

      user.documents = await userService.parseDocumentsImages(documentsFile)
      const data = await userService.insertUser(user);
      return data;
    } catch (err) {
      console.log("err", err);
    }
  };

  const updateUser = async (user, avatar, documentsFile) => {
    const userService = new UserService();

    try {
      if (avatar.file) {
        const avatarImage = await putFile(avatar.file);
        user.avatar = avatarImage;
      }

      if (user.avatar && user.avatar.key.length === 0) {
        user.avatar = null;
      }

      user.documents = await userService.parseDocumentsImages(documentsFile)
      const data = await userService.updateUser(user);
      return data;
    } catch (err) {
      console.log("err", err);
    }
  };

  const value = useMemo(
    () => ({
      ...state,
      fetchUsers,
      fetchUser,  
      setIsLoading,
      insertUser,
      updateUser,
      setUsers,
      setUser,
      setAvatarImage,
      setDocumentImages,
      cleanRoomForm,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  return context;
};

export const ManagedUserContext = ({ children }) => {
  const [, setDataLayout] = useLayout();

  useEffect(() => {
    setDataLayout((datalayout) => ({
      ...datalayout,
      drawerTitle: "Users",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UserProvider>{children}</UserProvider>;
};
