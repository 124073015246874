export const amenity = {
  id: null,
  sortOrder: 0,
  tooltip: '',
  description: '',
  icon: 'fa fa-check',
  grouping: '',
};

export const initialState = {
  amenity,
  amenities: [],
  isLoading: false,
}

export function amenityReducer(state, action) {
  switch (action.type) {
    case 'SET_AMENITIY': {
      return {
        ...state,
        amenity: action.amenity,
      }
    }

    case 'SET_AMENITIES': {
      return {
        ...state,
        amenities: action.amenities,
      }
    }

    case 'SET_IS_LOADING': {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case 'CLEAN_AMENITY_FORM': {
      return {
        ...state,
        amenity,
      }
    }

    default:
      return state
  }
}
