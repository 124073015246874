import { ManagedAmenityContext } from "modules/Amenity/AmenityContext";
import React from "react";
import { Route } from "react-router-dom";
import { RoomList, RoomForm } from "../index";
import { ManagedRoomContext } from "../RoomContext";

const RoomRoute = () => {
  return (
    <ManagedAmenityContext>
      <ManagedRoomContext>
        <Route path="/rooms" component={RoomList} exact />
        <Route path="/rooms/:id" component={RoomForm} />
      </ManagedRoomContext>
    </ManagedAmenityContext>
  );
};

export default RoomRoute;
