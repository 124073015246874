import * as Yup from "yup";

const Userchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  email: Yup.string().required("Required").email('Invalid email'),
  username: Yup.string().required("Required"),
  address1: Yup.string().required("Required"),
});

export default Userchema;
