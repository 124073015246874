import { listPropertys, listRooms, getProperty } from '@data/queries'
import { updateProperty as gqUpdateProperty, createProperty as gqCreateProperty  } from '@data/mutations'
import { putFile, deleteFile } from '@config'
import { API, graphqlOperation } from 'aws-amplify';

export default class PropertyService {
  async getList() {
    try {
      const {
        data: {
          listPropertys: {
            items
          }
        }
      } = await API.graphql(graphqlOperation(listPropertys));
      
      return items;
    } catch (err) {
      throw err;
    }
  }

  async getRooms(propertyId) {
    const filter = {
      propertyID: {
        eq: propertyId,
      }
    };

    try {
      const {
        data: {
          listRooms: {
            items
          }
        }
      } = await API.graphql(graphqlOperation(listRooms, filter));
      
      return items;
    } catch (err) {
      throw err;
    }
  }

  async getProperty(propertyId) {
    try {
      const {
        data: {
          getProperty: data
        }
      } = await API.graphql(graphqlOperation(getProperty, { id: propertyId }));
      
      return data;
    } catch (err) {
      throw err;
    }
  }

  async insertProperty(propertyData) {
    try {
      const {
        data: {
          gqCreateProperty: data
        }
      } = await API.graphql(graphqlOperation(gqCreateProperty, { input: propertyData }));
      
      return data;
    } catch (err) {
      throw err;
    }
  }

  async updateProperty(property) {
    try {
      const {
        data
      } = await API.graphql(
        graphqlOperation(gqUpdateProperty, { input: property })
      );
      
      return data;
    } catch (err) {
      throw err;
    }
  }

  async parsePropertyImages(imagesFile) {
    
    const _propertyImages = []
    if (imagesFile.length > 0) {
      for (const imageFile of imagesFile) {
        if (imageFile.action === 'ADD') {
          const imageProperty = await putFile(imageFile.file)
          _propertyImages.push(imageProperty.key);
        }

        if (imageFile.action === 'KEEP') {
          _propertyImages.push(imageFile.key);
        }


        if (imageFile.action === 'DELETE') {
          const imageProperty = await deleteFile(imageFile.key)
         
        }
      }
    }

    return _propertyImages.filter(propertyImage => propertyImage.action !== 'DELETE')
  }
}
