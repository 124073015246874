import React from "react";
import { Route } from "react-router-dom";
import { ContentList, ContentForm } from "../index"


const ContentRoute = () => {
  return (
    <React.Fragment>
      <Route path="/content" component={ContentList} exact />
      <Route path="/content/:id" component={ContentForm} />
    </React.Fragment>
  );
};

export default ContentRoute;
