import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { useTable, useSortBy, usePagination } from "react-table";
import { Button, MenuItem, Paper, TextField, Select } from "@material-ui/core";
import clsx from "clsx";
import { useStyles as rootStyles } from "@config";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Add, ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

// const Styles = styled.div`
//   padding: 1rem;

//   table {

//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }
//   }
// `

const defaultPageSize = 30
const pageSizes = [defaultPageSize, 40, 50]
function Table( props) {
  const { columns, data, onRowClick, onAddRow, morePageSize, manualPageCount,showPageSizes, handlePagination, customPagination} = props;
  
  const classes = useStyles();
  const [pagings, setPagings] = useState(pageSizes);
  const genericClasses = rootStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    manualPagination,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      pageCount:manualPageCount,
      manualPagination: customPagination,
      autoResetPage: !customPagination,
      initialState: {
        pageIndex: 0,
        hiddenColumns: ["id"],
        pageSize: defaultPageSize
      },
    },
    useSortBy,
    usePagination
  );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const fixedHeightPaper = clsx(classes.paper, genericClasses.fixedHeight);

  useEffect(() => {
    if (morePageSize && morePageSize.length > 0) {
      setPagings(() => [
        ...pageSizes,
        ...morePageSize,
      ])
    }
  }, [morePageSize])

  useEffect(() => {
   if(customPagination) handlePagination(pageIndex);
  }, [pageIndex])
  return (
    <>
      <Paper className={fixedHeightPaper}>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, idx) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    className={classes.tableHeaderCell}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {idx === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{column.render("Header")}</div>

                        <div
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Add onClick={() => onAddRow()} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {column.render("Header")}
                        <span
                          style={{
                            marginTop: "12em",
                          }}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDropUp />
                            ) : (
                              <ArrowDropDown />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  className={clsx(classes.TableCellRow, {
                    [classes.TableEvenCellRow]: i % 2 === 0,
                    [classes.TableOddCellRow]: i % 2 === 1,
                  })}
                  {...row.getRowProps()}
                  onClick={() => onRowClick && onRowClick(row.values)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        className={classes.TableCell}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>

        <br />
        {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}

        <div className={classes.pagination}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <i
                className={`fas fa-angle-double-left fa-2x`}
                aria-hidden="true"
              />
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <i className={`fas fa-angle-left fa-2x`} aria-hidden="true" />
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <i className={`fas fa-angle-right fa-2x`} aria-hidden="true" />
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <i
                className={`fas fa-angle-double-right fa-2x`}
                aria-hidden="true"
              />
            </Button>{" "}
          </div>

          <div className={classes.paginationNumering}>
            <div className={classes.paginationInformation}>
              <div>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </div>

              {/* <div style={{ marginLeft: "0.5em" }}>{`| Go to page: `}</div> */}
            </div>

            <div className={classes.paginationInput}>
              {/* <div style={{ marginLeft: "0.5em" }}>
                <TextField
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                />
              </div> */}

              {showPageSizes && <div>
                <Select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {pagings.map((pageSize) => (
                    <MenuItem key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </MenuItem>
                  ))}
                </Select>
              </div>}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
}

export default Table;
