import { v4 as uuidv4 } from 'uuid';

export const price = {
  id: uuidv4(),
  title: 'New Price Title',
  amount: 0,
  amountType: '',
  displayAmount: 0,
  type: '',
  increment: 0,
  incrementType: '',
  stripePriceId: '',
  recurrenceType: '',
  description: '',
}

export const room = {
  id: '',
  title: '',
  description: '',
  hasBathroom: true,
  active: '',
  price: 0,
  discount: 0,
  heroImage: {
    bucket: '',
    description: '',
    key: '',
    region: '',
  },
  images: [],
  prices: [],
  sqft: 0,
  bedType: '',
};

export const initialState = {
  room,
  rooms: [],
  isLoading: false,
  coverImage: {
    file: null,
    url: ''
  },
  roomImages: [],
  properties: [],
}

export function roomReducer(state, action) {
  switch (action.type) {
    case 'SET_ROOM': {
      return {
        ...state,
        room: action.room,
      }
    }

    case 'SET_ROOMS': {
      return {
        ...state,
        rooms: action.rooms,
      }
    }

    case 'SET_IS_LOADING': {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case 'SET_COVER_IMAGE': {
      return {
        ...state,
        coverImage: action.coverImage,
      }
    }

    case 'SET_ROOM_IMAGES': {
      return {
        ...state,
        roomImages: action.roomImages,
      }
    }

    case 'CLEAN_ROOM_FORM': {
      return {
        ...state,
        room,
        coverImage: {
          file: null,
          url: ''
        },
        roomImages: []
      }
    }

    case 'SET_PROPERTIES': {
      return {
        ...state,
        properties: action.properties,
      }
    }

    default:
      return state
  }
}
