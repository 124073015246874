import * as React from "react";
import {
  AnalyticsDashboard,
  SessionsByDateChart,
  ActiveUsersChart,
  PagesPerSessionChart,
  SessionsByHourChart,
  PageViewsPerPathChart,
} from "react-analytics-charts";

const MyDashboard = () => {
  const clientId =
    "985439540415-pioejn80071apr6crnm92d5fo0i369dm.apps.googleusercontent.com";
  const viewId = "ga:249750345";
  return (
    <AnalyticsDashboard
      chartsAppearFirst={true}
      signOutButtonText="Sign Out of Analytics"
      authOptions={{ clientId }}
      hideViewSelector={true}
      viewId={viewId}
      renderCharts={(gapi, viewId) => {
        const chartStyles = {
          margin: "15px",
          maxWidth: 400,
        };
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <SessionsByDateChart
              gapi={gapi}
              viewId={viewId}
              style={chartStyles}
              showPageViews
              showUsers
            />
            <ActiveUsersChart
              gapi={gapi}
              viewId={viewId}
              days={28}
              activeUserDays={7}
              style={chartStyles}
            />
            <PagesPerSessionChart
              gapi={gapi}
              viewId={viewId}
              style={chartStyles}
            />
            <SessionsByHourChart
              gapi={gapi}
              viewId={viewId}
              style={chartStyles}
            />
            <PageViewsPerPathChart
              gapi={gapi}
              viewId={viewId}
              style={{ margin: "15px" }}
            />
          </div>
        );
      }}
    />
  );
};

export default MyDashboard;
