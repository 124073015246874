import React from "react";
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  st0: {
		fill: '#C6C6C6',
	},
	st1: {
		fill: '#FFFFFF',
	},
	st2: {
		fill: '#728E32',
	},
	st3: {
		fill: '#575756',
	},
	st4: {
		fill: 'none',
	},
	st5: {
		fill: '#333333',
	},
	st6: {
		fill: '#706F6F',
		stroke: '#706F6F',
		strokeWidth: 0.2,
		strokeMiterlimit: 10,
	},
}));


const Logo = (props) => {
  const classes = useStyles();
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 300 200"
      style={{
				enableBackground: 'new 0 0 300 200',
			}}
			{...props}
    >
      <g>
        <g>
          <path
            className={classes.st2}
            d="M152.93,84.83v28.91c0,3.58-1.04,6.43-3.1,8.47c-2,2.02-4.82,3.04-8.38,3.04c-3.58,0-6.41-1.02-8.41-3.04
			c-2.05-2.04-3.1-4.88-3.1-8.47c0-3.57,1.04-6.42,3.1-8.47c2-2,4.83-3.01,8.41-3.01c3.56,0,6.38,1.01,8.38,3.01
			c0.3,0.3,0.58,0.62,0.85,0.96l0.98,1.26v-5.89l-0.21-0.16c-2.62-2.06-5.98-3.11-10-3.11c-4.65,0-8.42,1.4-11.2,4.16
			c-2.82,2.82-4.25,6.61-4.25,11.26c0,4.65,1.43,8.45,4.25,11.29c2.78,2.76,6.55,4.16,11.2,4.16c4.65,0,8.41-1.4,11.18-4.16
			c2.82-2.84,4.25-6.64,4.25-11.29V84.83H152.93z"
          />
          <path
            className={classes.st3}
            d="M252.34,84.83v28.91c0,3.58-1.04,6.43-3.1,8.47c-2,2.02-4.82,3.04-8.38,3.04c-3.59,0-6.41-1.02-8.41-3.04
			c-2.05-2.04-3.1-4.88-3.1-8.47c0-3.57,1.04-6.42,3.1-8.47c2-2,4.83-3.01,8.41-3.01c3.56,0,6.38,1.01,8.38,3.01
			c0.3,0.3,0.58,0.62,0.85,0.96l0.98,1.25v-5.88l-0.21-0.16c-2.62-2.06-5.98-3.11-10-3.11c-4.65,0-8.42,1.4-11.2,4.16
			c-2.82,2.82-4.25,6.61-4.25,11.26c0,4.65,1.43,8.45,4.25,11.29c2.78,2.76,6.55,4.16,11.2,4.16c4.65,0,8.41-1.4,11.18-4.16
			c2.82-2.84,4.25-6.64,4.25-11.29V84.83H252.34z"
          />
          <path
            className={classes.st3}
            d="M186.36,102.47c-2.78-2.76-6.55-4.16-11.2-4.16c-4.02,0-7.38,1.05-10,3.11l-0.21,0.16v5.89l0.98-1.26
			c0.26-0.34,0.55-0.66,0.85-0.96c2-2,4.82-3.01,8.38-3.01c3.58,0,6.41,1.01,8.41,3.01c2.06,2.06,3.1,4.91,3.1,8.47
			c0,3.58-1.04,6.43-3.1,8.47c-2,2.02-4.82,3.04-8.41,3.04c-3.57,0-6.38-1.02-8.38-3.04c-2.06-2.04-3.1-4.89-3.1-8.47V84.83h-3.94
			v28.91c0,4.65,1.43,8.45,4.25,11.29c2.76,2.76,6.52,4.16,11.17,4.16c4.65,0,8.42-1.4,11.2-4.16c2.82-2.84,4.25-6.64,4.25-11.29
			C190.61,109.08,189.18,105.29,186.36,102.47z"
          />
          <g>
            <path
              className={classes.st4}
              d="M116.56,105.33c-2.21-1.99-4.73-2.96-7.69-2.96c-2.98,0-5.49,0.97-7.69,2.96c-1.95,1.76-3.12,3.83-3.57,6.32
				h22.52C119.66,109.19,118.47,107.07,116.56,105.33z"
            />
            <path
              className={classes.st2}
              d="M115.22,123.38c-1.92,1.28-4.05,1.93-6.35,1.93c-3.07,0-5.65-1.02-7.88-3.13c-1.99-1.89-3.13-4.11-3.47-6.74
				h26.52l0.08-1.59c0-0.4-0.03-0.87-0.08-1.41c-0.36-3.87-2-7.17-4.88-9.82c-2.9-2.67-6.36-4.03-10.3-4.03
				c-4.18,0-7.81,1.51-10.79,4.48c-2.96,2.96-4.46,6.58-4.46,10.77c0,4.18,1.5,7.81,4.46,10.77c2.97,2.97,6.6,4.48,10.79,4.48
				c3.23,0,6.19-0.95,8.81-2.84c2.22-1.59,3.86-3.65,4.97-6.09h-4.26C117.56,121.43,116.51,122.51,115.22,123.38z M101.18,105.34
				c2.2-1.99,4.72-2.96,7.69-2.96c2.96,0,5.48,0.97,7.69,2.96c1.91,1.73,3.1,3.85,3.57,6.32H97.61
				C98.06,109.17,99.23,107.09,101.18,105.34z"
            />
          </g>
          <g>
            <path
              className={classes.st4}
              d="M215.39,105.33c-2.21-1.99-4.72-2.96-7.69-2.96c-2.98,0-5.49,0.97-7.69,2.96c-1.95,1.76-3.12,3.83-3.57,6.32
				h22.52C218.49,109.19,217.3,107.07,215.39,105.33z"
            />
            <path
              className={classes.st5}
              d="M214.05,123.38c-1.92,1.28-4.05,1.93-6.35,1.93c-3.07,0-5.65-1.02-7.88-3.13c-1.99-1.89-3.13-4.11-3.47-6.74
				h26.52l0.08-1.59c0-0.4-0.03-0.87-0.08-1.41c-0.36-3.87-2-7.18-4.88-9.82c-2.89-2.67-6.36-4.03-10.3-4.03
				c-4.19,0-7.82,1.51-10.79,4.48c-2.96,2.96-4.46,6.59-4.46,10.77c0,4.18,1.5,7.81,4.46,10.77c2.97,2.97,6.6,4.48,10.79,4.48
				c3.23,0,6.19-0.95,8.81-2.84c2.22-1.59,3.86-3.65,4.97-6.09h-4.26C216.39,121.43,215.34,122.51,214.05,123.38z M200.01,105.34
				c2.2-1.99,4.72-2.96,7.69-2.96c2.96,0,5.48,0.97,7.69,2.96c1.91,1.73,3.1,3.85,3.57,6.32h-22.52
				C196.89,109.17,198.06,107.09,200.01,105.34z"
            />
          </g>
          <g>
            <path
              className={classes.st2}
              d="M224.02,100.27c-0.36,0-0.71-0.11-1.01-0.33c-0.45-0.33-10.97-8.09-10.97-18.18
				c0-6.5,5.49-11.98,11.98-11.98S236,75.26,236,81.76c0,10.09-10.52,17.85-10.97,18.18C224.74,100.16,224.38,100.27,224.02,100.27z
				 M224.02,73.23c-4.63,0-8.53,3.91-8.53,8.53c0,6.69,6.07,12.5,8.53,14.57c2.46-2.08,8.53-7.9,8.53-14.57
				C232.56,77.13,228.65,73.23,224.02,73.23z"
            />
          </g>
          <g>
            <path
              className={classes.st3}
              d="M224.02,87.85c-3.36,0-6.09-2.73-6.09-6.09s2.73-6.09,6.09-6.09c3.36,0,6.09,2.73,6.09,6.09
				S227.38,87.85,224.02,87.85z M224.02,79.12c-1.45,0-2.64,1.18-2.64,2.64c0,1.45,1.19,2.64,2.64,2.64s2.64-1.18,2.64-2.64
				C226.66,80.3,225.48,79.12,224.02,79.12z"
            />
          </g>
          <g>
            <path
              className={classes.st4}
              d="M58.85,74.42c0.71-0.81,1.35-1.62,1.95-2.41c-3.21,1.75-7.12,2.78-11.34,2.78c-4.21,0-8.12-1.03-11.33-2.77
				c0.6,0.79,1.24,1.59,1.94,2.4c3.62,4.15,7.68,7.31,9.39,8.56C51.17,81.72,55.23,78.57,58.85,74.42z"
            />
            <path
              className={classes.st2}
              d="M91.06,89.03c-0.08-1.34-0.6-2.43-1.61-3.33c-1.02-0.91-2.24-1.35-3.73-1.35c-1.41,0-2.6,0.42-3.62,1.28
				c-0.98,0.81-1.59,1.84-1.87,3.13l-4.45,21.11c-0.16,0.66-0.6,1.03-1.17,1.03c-0.27,0-0.92-0.09-1.18-0.95l-0.01-0.06l-4.45-21.14
				c-0.28-1.27-0.92-2.3-1.96-3.14c-1.06-0.86-2.26-1.27-3.68-1.27c-1.49,0-2.69,0.44-3.67,1.36c-0.94,0.88-1.44,1.96-1.52,3.31
				l-0.22,35.1c0,1.79-1.46,3.25-3.25,3.25c-1.79,0-3.25-1.46-3.25-3.25V85.8c3.92-2.83,17.77-13.72,17.77-26.24v-0.22v-8.79
				c0-4.17-3.32-7.57-7.46-7.71c-0.08-0.01-0.15-0.02-0.23-0.02c-0.03,0-0.06,0.01-0.1,0.01h-0.15v0.02
				c-0.82,0.12-1.45,0.82-1.45,1.67c0,0.85,0.63,1.55,1.45,1.67v0.08h0.22c2.36,0,4.29,1.92,4.29,4.29v8.79v0.22
				c0,6.5-7.31,11.79-16.29,11.79s-16.29-5.29-16.29-11.79v-0.22v-8.79c0-2.36,1.92-4.29,4.29-4.29h0.22v-0.06
				c0.92-0.02,1.65-0.77,1.65-1.69c0-0.94-0.76-1.69-1.69-1.69c-0.03,0-0.06,0.01-0.1,0.01h-0.08c-4.26,0-7.72,3.46-7.72,7.72v8.79
				v0.22c0,12.52,13.86,23.41,17.77,26.24v38.33c0,3.94,3.21,7.15,7.15,7.15c3.94,0,7.15-3.21,7.15-7.15v-2.72L62,92.45l0.16-2.92
				c0.08-1.44,1.01-1.56,1.29-1.56c0.37,0,1.25,0.14,1.53,1.45l4.55,21.61c0.22,1.05,0.84,1.85,1.9,2.45
				c0.92,0.51,1.99,0.77,3.18,0.77c1.18,0,2.24-0.26,3.15-0.77c1.07-0.6,1.7-1.4,1.92-2.45l4.52-21.61
				c0.28-1.27,1.06-1.46,1.51-1.46c0.38,0,1.27,0.16,1.34,1.65l0.16,2.83l0.03,40.97h4.03L91.06,89.03z M49.46,82.97
				c-1.71-1.25-5.77-4.41-9.39-8.56c-0.7-0.81-1.35-1.61-1.94-2.4c3.21,1.75,7.12,2.77,11.33,2.77c4.22,0,8.13-1.03,11.34-2.78
				c-0.6,0.8-1.25,1.6-1.95,2.41C55.23,78.57,51.17,81.72,49.46,82.97z"
            />
          </g>
          <g>
            <path
              className={classes.st3}
              d="M89.3,144.07c-3.4,0-6.16-2.76-6.16-6.16c0-3.39,2.76-6.16,6.16-6.16c3.39,0,6.16,2.76,6.16,6.16
				C95.46,141.31,92.7,144.07,89.3,144.07z M89.3,135.2c-1.49,0-2.71,1.21-2.71,2.71c0,1.49,1.21,2.71,2.71,2.71
				s2.71-1.21,2.71-2.71C92.01,136.42,90.79,135.2,89.3,135.2z"
            />
          </g>
        </g>
        <g>
          <path
            className={classes.st3}
            d="M263.19,87.89h-1.63v-1.25h4.65v1.25h-1.65v5.33h-1.36V87.89z"
          />
          <path
            className={classes.st3}
            d="M272.46,90.7c-0.03-0.79-0.05-1.74-0.05-2.7h-0.02c-0.2,0.84-0.45,1.78-0.68,2.55l-0.74,2.57h-1.07
			l-0.65-2.55c-0.2-0.77-0.4-1.7-0.55-2.57h-0.02c-0.03,0.89-0.06,1.9-0.1,2.72l-0.12,2.5h-1.26l0.39-6.58h1.82l0.59,2.2
			c0.2,0.76,0.38,1.58,0.51,2.35h0.04c0.16-0.76,0.37-1.63,0.57-2.36l0.64-2.19h1.79l0.32,6.58h-1.33L272.46,90.7z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
