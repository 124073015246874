import React, { createContext, useState } from 'react';

export const LayoutContext = createContext([{}, () => { }]);

export function LayoutProvider({ children }) {
  const [state, setState] = useState({
    openDrawer: true,
    drawerTitle: 'Medbed Admin',
  });

  return (
    <LayoutContext.Provider value={[state, setState]}>
      {children}
    </LayoutContext.Provider>
  );
}

export const useLayout = () => {
  const context = React.useContext(LayoutContext)

  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }

  return context
}
