import { listUsers, getUser } from '@data/queries'
import { updateUser as gqUpdateUser, createUser as gqCreateUser  } from '@data/mutations'
import { API, graphqlOperation } from 'aws-amplify';
import { putFile } from '@config';

export default class UserService {
  async getList() {
    try {
      const {
        data: {
          listUsers: {
            items
          }
        }
      } = await API.graphql(graphqlOperation(listUsers, {limit:1000}));
      
      return items;
    } catch (err) {
      throw err;
    }
  }

  async getUser(userId) {
    try {
      const {
        data: {
          getUser: data
        }
      } = await API.graphql(graphqlOperation(getUser, { id: userId }));
      
      return data;
    } catch (err) {
      throw err;
    }
  }

  async insertUser(userData) {
    try {
      const {
        data: {
          gqCreateUser: data
        }
      } = await API.graphql(graphqlOperation(gqCreateUser, { input: userData }));
      
      return data;
    } catch (err) {
      throw err;
    }
  }

  async updateUser(user) {
    try {
      const {
        data
      } = await API.graphql(
        graphqlOperation(gqUpdateUser, { input: user })
      );
      
      return data;
    } catch (err) {
      throw err;
    }
  }

  async parseDocumentsImages(imagesFile) {
    const _propertyImages = []
    if (imagesFile.length > 0) {
      for (const imageFile of imagesFile) {
        if (imageFile.action === 'ADD') {
          const imageProperty = await putFile(imageFile.file)
          _propertyImages.push({
            ...imageProperty,
            description: imageFile.description
          })
        }

        if (imageFile.action === 'KEEP') {
          _propertyImages.push({
            bucket: imageFile.bucket,
            key: imageFile.key,
            region: imageFile.region,
            description: imageFile.description
          })
        }
      }
    }

    return _propertyImages.filter(propertyImage => propertyImage.action !== 'DELETE')
  }
}
