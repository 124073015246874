
const sortByRoom = (a, b) => {
  var nameA = `${a.property.title.toUpperCase()} ${a.title.toUpperCase()}`; // ignore upper and lowercase
  var nameB = `${b.property.title.toUpperCase()} ${b.title.toUpperCase()}`; // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};


export default class RoomListService {
  parseList(rooms) {
    // console.log('rooms', rooms)
    return rooms.map(({
      id,
      title,
      price,
      discount,
      hasBathroom,
      active,
      Property
    }) => {
      return {
        id,
        title,
        price,
        discount,
        hasBathroom,
        active,
        property: Property ? Property : null
      }
    })
    
    .sort(sortByRoom)
  }
}
