import React from "react";
import { Route } from "react-router-dom";
import { UserList, UserForm } from "../index";
import { ManagedUserContext } from "../UserContext";

const UserRoute = () => {
  return (
    <ManagedUserContext>
      <Route path="/users" component={UserList} exact />
      <Route path="/users/:id" component={UserForm} />
    </ManagedUserContext>
  );
};

export default UserRoute;
