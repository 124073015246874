/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      address1
      address2
      city
      state
      postalCode
      email
      phone
      terms
      documents {
        description
        bucket
        region
        key
      }
      avatar {
        description
        bucket
        region
        key
      }
      username
      paymentProcessorId
      createdAt
      updatedAt
      Reservation {
        items {
          id
          friendlyId
          createDatetime
          paymentProcessorData
          paymentPlan
          reservationDetails
          checkoutDetails
          checkInDate
          checkOutDate
          totalAmount
          referral
          status
          roomId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      Review {
        items {
          id
          rating
          comment
          featured
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      address1
      address2
      city
      state
      postalCode
      email
      phone
      terms
      documents {
        description
        bucket
        region
        key
      }
      avatar {
        description
        bucket
        region
        key
      }
      username
      paymentProcessorId
      createdAt
      updatedAt
      Reservation {
        items {
          id
          friendlyId
          createDatetime
          paymentProcessorData
          paymentPlan
          reservationDetails
          checkoutDetails
          checkInDate
          checkOutDate
          totalAmount
          referral
          status
          roomId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      Review {
        items {
          id
          rating
          comment
          featured
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      address1
      address2
      city
      state
      postalCode
      email
      phone
      terms
      documents {
        description
        bucket
        region
        key
      }
      avatar {
        description
        bucket
        region
        key
      }
      username
      paymentProcessorId
      createdAt
      updatedAt
      Reservation {
        items {
          id
          friendlyId
          createDatetime
          paymentProcessorData
          paymentPlan
          reservationDetails
          checkoutDetails
          checkInDate
          checkOutDate
          totalAmount
          referral
          status
          roomId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      Review {
        items {
          id
          rating
          comment
          featured
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      id
      baths
      beds
      title
      address
      city
      state
      postalCode
      description
      shortDescription
      instructions
      type
      heroImage
      emailAttachment {
        description
        bucket
        region
        key
      }
      images
      createdAt
      updatedAt
      Room {
        items {
          id
          title
          description
          hasBathroom
          heroImage
          images
          price
          discount
          sqft
          bedType
          fees
          emailTemplate
          createdAt
          updatedAt
        }
        nextToken
      }
      Amenities {
        items {
          id
          sortOrder
          tooltip
          description
          icon
          grouping
          createdAt
          updatedAt
        }
        nextToken
      }
      Review {
        items {
          id
          rating
          comment
          featured
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      id
      baths
      beds
      title
      address
      city
      state
      postalCode
      description
      shortDescription
      instructions
      type
      heroImage
      emailAttachment {
        description
        bucket
        region
        key
      }
      images
      createdAt
      updatedAt
      Room {
        items {
          id
          title
          description
          hasBathroom
          heroImage
          images
          price
          discount
          sqft
          bedType
          fees
          emailTemplate
          createdAt
          updatedAt
        }
        nextToken
      }
      Amenities {
        items {
          id
          sortOrder
          tooltip
          description
          icon
          grouping
          createdAt
          updatedAt
        }
        nextToken
      }
      Review {
        items {
          id
          rating
          comment
          featured
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      id
      baths
      beds
      title
      address
      city
      state
      postalCode
      description
      shortDescription
      instructions
      type
      heroImage
      emailAttachment {
        description
        bucket
        region
        key
      }
      images
      createdAt
      updatedAt
      Room {
        items {
          id
          title
          description
          hasBathroom
          heroImage
          images
          price
          discount
          sqft
          bedType
          fees
          emailTemplate
          createdAt
          updatedAt
        }
        nextToken
      }
      Amenities {
        items {
          id
          sortOrder
          tooltip
          description
          icon
          grouping
          createdAt
          updatedAt
        }
        nextToken
      }
      Review {
        items {
          id
          rating
          comment
          featured
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createRoom = /* GraphQL */ `
  mutation CreateRoom(
    $input: CreateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    createRoom(input: $input, condition: $condition) {
      id
      title
      description
      hasBathroom
      heroImage
      images
      price
      discount
      prices {
        id
        title
        description
        stripePriceId
        amount
        displayAmount
        type
        amountType
        recurrenceType
        incrementType
        increment
      }
      sqft
      bedType
      fees
      emailTemplate
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
      Reservation {
        items {
          id
          friendlyId
          createDatetime
          paymentProcessorData
          paymentPlan
          reservationDetails
          checkoutDetails
          checkInDate
          checkOutDate
          totalAmount
          referral
          status
          roomId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      Amenities {
        items {
          id
          sortOrder
          tooltip
          description
          icon
          grouping
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateRoom = /* GraphQL */ `
  mutation UpdateRoom(
    $input: UpdateRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    updateRoom(input: $input, condition: $condition) {
      id
      title
      description
      hasBathroom
      heroImage
      images
      price
      discount
      active
      prices {
        id
        title
        description
        stripePriceId
        amount
        displayAmount
        type
        amountType
        recurrenceType
        incrementType
        increment
      }
      sqft
      bedType
      fees
      emailTemplate
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
      Reservation {
        items {
          id
          friendlyId
          createDatetime
          paymentProcessorData
          paymentPlan
          reservationDetails
          checkoutDetails
          checkInDate
          checkOutDate
          totalAmount
          referral
          status
          roomId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      Amenities {
        items {
          id
          sortOrder
          tooltip
          description
          icon
          grouping
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteRoom = /* GraphQL */ `
  mutation DeleteRoom(
    $input: DeleteRoomInput!
    $condition: ModelRoomConditionInput
  ) {
    deleteRoom(input: $input, condition: $condition) {
      id
      title
      description
      hasBathroom
      heroImage
      images
      price
      discount
      prices {
        id
        title
        description
        stripePriceId
        amount
        displayAmount
        type
        amountType
        recurrenceType
        incrementType
        increment
      }
      sqft
      bedType
      fees
      emailTemplate
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
      Reservation {
        items {
          id
          friendlyId
          createDatetime
          paymentProcessorData
          paymentPlan
          reservationDetails
          checkoutDetails
          checkInDate
          checkOutDate
          totalAmount
          referral
          status
          roomId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      Amenities {
        items {
          id
          sortOrder
          tooltip
          description
          icon
          grouping
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      id
      friendlyId
      createDatetime
      paymentProcessorData
      paymentPlan
      reservationDetails
      checkoutDetails
      checkInDate
      checkOutDate
      totalAmount
      referral
      status
      roomId
      userId
      User {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        postalCode
        email
        phone
        terms
        documents {
          description
          bucket
          region
          key
        }
        avatar {
          description
          bucket
          region
          key
        }
        username
        paymentProcessorId
        createdAt
        updatedAt
        Reservation {
          nextToken
        }
        Review {
          nextToken
        }
      }
      createdAt
      updatedAt
      Room {
        id
        title
        description
        hasBathroom
        heroImage
        images
        price
        discount
        prices {
          id
          title
          description
          stripePriceId
          amount
          displayAmount
          type
          amountType
          recurrenceType
          incrementType
          increment
        }
        sqft
        bedType
        fees
        emailTemplate
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
        Reservation {
          nextToken
        }
        Amenities {
          nextToken
        }
      }
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      id
      friendlyId
      createDatetime
      paymentProcessorData
      paymentPlan
      reservationDetails
      checkoutDetails
      checkInDate
      checkOutDate
      totalAmount
      referral
      status
      roomId
      userId
      User {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        postalCode
        email
        phone
        terms
        documents {
          description
          bucket
          region
          key
        }
        avatar {
          description
          bucket
          region
          key
        }
        username
        paymentProcessorId
        createdAt
        updatedAt
        Reservation {
          nextToken
        }
        Review {
          nextToken
        }
      }
      createdAt
      updatedAt
      Room {
        id
        title
        description
        hasBathroom
        heroImage
        images
        price
        discount
        prices {
          id
          title
          description
          stripePriceId
          amount
          displayAmount
          type
          amountType
          recurrenceType
          incrementType
          increment
        }
        sqft
        bedType
        fees
        emailTemplate
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
        Reservation {
          nextToken
        }
        Amenities {
          nextToken
        }
      }
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      id
      friendlyId
      createDatetime
      paymentProcessorData
      paymentPlan
      reservationDetails
      checkoutDetails
      checkInDate
      checkOutDate
      totalAmount
      referral
      status
      roomId
      userId
      User {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        postalCode
        email
        phone
        terms
        documents {
          description
          bucket
          region
          key
        }
        avatar {
          description
          bucket
          region
          key
        }
        username
        paymentProcessorId
        createdAt
        updatedAt
        Reservation {
          nextToken
        }
        Review {
          nextToken
        }
      }
      createdAt
      updatedAt
      Room {
        id
        title
        description
        hasBathroom
        heroImage
        images
        price
        discount
        prices {
          id
          title
          description
          stripePriceId
          amount
          displayAmount
          type
          amountType
          recurrenceType
          incrementType
          increment
        }
        sqft
        bedType
        fees
        emailTemplate
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
        Reservation {
          nextToken
        }
        Amenities {
          nextToken
        }
      }
    }
  }
`;
export const createConfig = /* GraphQL */ `
  mutation CreateConfig(
    $input: CreateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    createConfig(input: $input, condition: $condition) {
      id
      cleaningFee
      setUpFee
      setUpPercent
      createdAt
      updatedAt
    }
  }
`;
export const updateConfig = /* GraphQL */ `
  mutation UpdateConfig(
    $input: UpdateConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    updateConfig(input: $input, condition: $condition) {
      id
      cleaningFee
      setUpFee
      setUpPercent
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfig = /* GraphQL */ `
  mutation DeleteConfig(
    $input: DeleteConfigInput!
    $condition: ModelConfigConditionInput
  ) {
    deleteConfig(input: $input, condition: $condition) {
      id
      cleaningFee
      setUpFee
      setUpPercent
      createdAt
      updatedAt
    }
  }
`;
export const createAmenity = /* GraphQL */ `
  mutation CreateAmenity(
    $input: CreateAmenityInput!
    $condition: ModelAmenityConditionInput
  ) {
    createAmenity(input: $input, condition: $condition) {
      id
      sortOrder
      tooltip
      description
      icon
      grouping
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
      Room {
        id
        title
        description
        hasBathroom
        heroImage
        images
        price
        discount
        prices {
          id
          title
          description
          stripePriceId
          amount
          displayAmount
          type
          amountType
          recurrenceType
          incrementType
          increment
        }
        sqft
        bedType
        fees
        emailTemplate
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
        Reservation {
          nextToken
        }
        Amenities {
          nextToken
        }
      }
    }
  }
`;
export const updateAmenity = /* GraphQL */ `
  mutation UpdateAmenity(
    $input: UpdateAmenityInput!
    $condition: ModelAmenityConditionInput
  ) {
    updateAmenity(input: $input, condition: $condition) {
      id
      sortOrder
      tooltip
      description
      icon
      grouping
      # createdAt
      # updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
      Room {
        id
        title
        description
        hasBathroom
        heroImage
        images
        price
        discount
        prices {
          id
          title
          description
          stripePriceId
          amount
          displayAmount
          type
          amountType
          recurrenceType
          incrementType
          increment
        }
        sqft
        bedType
        fees
        emailTemplate
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
        Reservation {
          nextToken
        }
        Amenities {
          nextToken
        }
      }
    }
  }
`;
export const deleteAmenity = /* GraphQL */ `
  mutation DeleteAmenity(
    $input: DeleteAmenityInput!
    $condition: ModelAmenityConditionInput
  ) {
    deleteAmenity(input: $input, condition: $condition) {
      id
      sortOrder
      tooltip
      description
      icon
      grouping
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
      Room {
        id
        title
        description
        hasBathroom
        heroImage
        images
        price
        discount
        prices {
          id
          title
          description
          stripePriceId
          amount
          displayAmount
          type
          amountType
          recurrenceType
          incrementType
          increment
        }
        sqft
        bedType
        fees
        emailTemplate
        createdAt
        updatedAt
        Property {
          id
          baths
          beds
          title
          address
          city
          state
          postalCode
          description
          shortDescription
          instructions
          type
          heroImage
          images
          createdAt
          updatedAt
        }
        Reservation {
          nextToken
        }
        Amenities {
          nextToken
        }
      }
    }
  }
`;
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      id
      contentTitle
      text
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      contentTitle
      text
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      id
      contentTitle
      text
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      rating
      comment
      featured
      User {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        postalCode
        email
        phone
        terms
        documents {
          description
          bucket
          region
          key
        }
        avatar {
          description
          bucket
          region
          key
        }
        username
        paymentProcessorId
        createdAt
        updatedAt
        Reservation {
          nextToken
        }
        Review {
          nextToken
        }
      }
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      rating
      comment
      featured
      User {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        postalCode
        email
        phone
        terms
        documents {
          description
          bucket
          region
          key
        }
        avatar {
          description
          bucket
          region
          key
        }
        username
        paymentProcessorId
        createdAt
        updatedAt
        Reservation {
          nextToken
        }
        Review {
          nextToken
        }
      }
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      rating
      comment
      featured
      User {
        id
        firstName
        lastName
        address1
        address2
        city
        state
        postalCode
        email
        phone
        terms
        documents {
          description
          bucket
          region
          key
        }
        avatar {
          description
          bucket
          region
          key
        }
        username
        paymentProcessorId
        createdAt
        updatedAt
        Reservation {
          nextToken
        }
        Review {
          nextToken
        }
      }
      createdAt
      updatedAt
      Property {
        id
        baths
        beds
        title
        address
        city
        state
        postalCode
        description
        shortDescription
        instructions
        type
        heroImage
        emailAttachment {
          description
          bucket
          region
          key
        }
        images
        createdAt
        updatedAt
        Room {
          nextToken
        }
        Amenities {
          nextToken
        }
        Review {
          nextToken
        }
      }
    }
  }
`;
