import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark
  },

  headingError: {
    fontWeight: 'bold',
    paddingLeft: '2em',
    color: 'red'
  },
}));

export default useStyles
