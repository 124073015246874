import React from "react";
import { Route } from "react-router-dom";
import { ReservationList, RevervationDetailInfo } from "../index";
import { ManagedReservationContext } from "../ReservationContext";

const ReservationRoute = () => {
  return (
    <ManagedReservationContext>
      <Route path="/reservations" component={ReservationList} exact />
      <Route path="/reservations/:id/info" component={RevervationDetailInfo} />
    </ManagedReservationContext>
  );
};

export default ReservationRoute;
