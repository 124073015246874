import { makeStyles } from '@material-ui/core';
import marginTop from './margins/marginTop';
import marginLeft from './margins/marginLeft'

export const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formControl: {
    width: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  ...marginTop,
  ...marginLeft,
  textField: {
    marginTop: '0.5em',
    width: '100%',
  },

  selectField: {
    marginTop: '0.5em',
    width: '100%',
  },

  drawerPaperFormActionClose: {
    display: 'flex',
    left: '0',
    position: 'fixed',
    flexDirection: 'column',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
 
    [theme.breakpoints.up("xs")]: {
     zIndex: '9999',
     left: "40px",
   },
 
   [theme.breakpoints.up("sm")]: {
     zIndex: '9999',
     left: "55px",
   },
 
   // [theme.breakpoints.up("md")]: {
   //   zIndex: '9999',
   //   left: "55px",
   // },
 
   [theme.breakpoints.up("lg")]: {
     left: "87px",
   },
 
   [theme.breakpoints.up("xl")]: {
     left: "90px",
   },
  },

  drawerPaperFormActionOpen: {
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: 'fixed',
    left: '250px',
    display: 'flex',
    flexDirection: 'column',
  }
}));
