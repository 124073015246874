import {
  // listReservations,
  getReservation,
  getUser,
  roomReserver,
} from "@data/queries";
import { listReservations } from "./ReservationListQuery";
import {
  updateReservation as gqUpdateReservation,
  createReservation as gqCreateReservation,
} from "@data/mutations";
import { API, graphqlOperation } from "aws-amplify";

export default class ReservationService {
  async getList(token) {
    try {
      
      const {
        data: {
          listReservations: { items: Reservations, nextToken },
        },
      } = await API.graphql(
        graphqlOperation(listReservations, { nextToken: token })
      );
        
      return { Reservations, nextToken: nextToken };
    } catch (err) {
      console.log(err)
      throw err;
    }
  }



  async getReservation(reservationId) {
    try {
      const {
        data: { getReservation: data },
      } = await API.graphql(
        graphqlOperation(getReservation, { id: reservationId })
      );

      return data;
    } catch (err) {
      throw err;
    }
  }

  async getDocumentUser(userId) {
    try {
      const {
        data: { getUser: data },
      } = await API.graphql(graphqlOperation(getUser, { id: userId }));

      return data;
    } catch (err) {
      throw err;
    }
  }

  async getReservationsByRoom(roomId) {
    try {
      const {
        data: {
          listReservations: { items: data },
        },
      } = await API.graphql(
        graphqlOperation(listReservations, {
          filter: {
            roomId: {
              eq: roomId,
            },
          },
        })
      );
      return data;
    } catch (err) {
      throw err;
    }
  }
  async getPaymentSchedule(options) {
    try {
      const {
        data: { roomReserver: data },
      } = await API.graphql({
        query: roomReserver,
        variables: options,
        authMode: "API_KEY",
      });
      return data;
    } catch (err) {
      throw err;
    }
  }
  // async insertReservation(reservation) {
  //   try {
  //     const {
  //       data: {
  //         gqCreateReservation: data
  //       }
  //     } = await API.graphql(graphqlOperation(gqCreateReservation, { input: reservation }));

  //     return data;
  //   } catch (err) {
  //     throw err;
  //   }
  // }

  // async updateReservation(reservation) {
  //   try {
  //     const {
  //       data
  //     } = await API.graphql(
  //       graphqlOperation(gqUpdateReservation, { input: reservation })
  //     );

  //     return data;
  //   } catch (err) {
  //     throw err;
  //   }
  // }
}
