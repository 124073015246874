import React from "react";
import { Dashboard } from "@modules";
import { Switch, Route, useLocation } from "react-router-dom";
import { PropertyRoute } from "@modules/Property";
import { RoomRoute } from "@modules/Room";
import { UserRoute } from '@modules/User';
import { ReservationRoute } from '@modules/Reservation';
import { ContentRoute } from '@modules/Content';


import { useEffect } from "react";

function AppRouter() {
  let location = useLocation();

  useEffect(() => {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  return (
    <Switch>
      <Route path="/properties" component={PropertyRoute} />
      <Route path="/rooms" component={RoomRoute} />
      <Route path="/users" component={UserRoute} />
      <Route path="/reservations" component={ReservationRoute} />
      <Route path="/content" component={ContentRoute} />


      <Route path="/dashboard">
        <Dashboard />
      </Route>

      <Route path="/">
        <Dashboard />
      </Route>
    </Switch>
  );
}

export default AppRouter;
