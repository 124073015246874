import React, { useEffect, useMemo } from "react";
// import clsx from 'clsx';
// import { useStyles } from '@components/SideMenu/styles';
import { Table } from "@components";
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import { useProperty } from "../PropertyContext";
import PropertyListService from "./PropertyListService";
import { useHistory } from "react-router-dom";
import { useStyles } from "@config";

const PropertyList = () => {
  const classes = useStyles();
  const columns = useMemo(
    () => [
      {
        Header: "Property List",
        columns: [
          {
            Header: `id`,
            accessor: "id",
          },
          {
            Header: `Title`,
            accessor: "title",
          },
          {
            Header: `Postal Code`,
            accessor: "postalCode",
          },
          {
            Header: "Short Description",
            accessor: "shortDescription",
          },
          {
            Header: "City",
            accessor: "city",
          },
          {
            Header: "Baths",
            accessor: "baths",
          },
          {
            Header: "Beds",
            accessor: "beds",
          },
        ],
      },
    ],
    []
  );
  let history = useHistory();
  const {
    properties,
    setProperties,
    isLoading,
    setIsLoading,
    fetchProperties,
  } = useProperty();
  // const classes = useStyles();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const propertyListService = new PropertyListService();
      const rawData = await fetchProperties();
      const _properties = propertyListService.parseList(rawData);

      setProperties(_properties);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function goTo(values) {
    history.push(`/properties/${values.id}`);
  }

  const data = React.useMemo(() => properties, [properties]);

  return (
    <Grid container spacing={1}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12}>
        <Table
          columns={columns}
          morePageSize={[]}
          // Just in case ;-)
          // morePageSize={[60, 70, 80]}
          data={data}
          onRowClick={(values) => goTo(values)}
          onAddRow={() => { goTo({ id: 'new' })}}
        />
      </Grid>
    </Grid>
  );
};

export default PropertyList;
