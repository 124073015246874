import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  paper: { width: '1200px' },
}));

export default function AlertDialog({
  open,
  title,
  textContent,
  children,
  handleClose,
  childrenActions,
}) {
  const classes = useStyles();
  return (
    <Dialog 
      maxWidth={'lg'}
      open={open}
      onClose={handleClose}
    >
      <div
        className={classes.paper}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

        <DialogContent>
          {textContent && (
            <DialogContentText id="alert-dialog-description">
              {textContent}
            </DialogContentText>
          )}

          {children && children}
        </DialogContent>

        <DialogActions>{childrenActions}</DialogActions>
      </div>
    </Dialog>
  );
}
