import React, { useCallback, useMemo }from "react";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import {
  acceptStyle,
  activeStyle,
  baseStyle,
  rejectStyle,
} from "./Dropzone.styles";

const DropzoneImageInput = ({ onDrop, imageURL, widthImage }) => {
  const dropZoneOptions = {
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    onDrop: useCallback(onDrop, [onDrop]),
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone(dropZoneOptions);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <section className="flex items-center flex-col xl:flex-row mt-4">
      <section className={clsx('')}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {imageURL ? (
            <div style={{
              width: widthImage || 'auto',
              height: '445px'
            }}>
              <img
              width={'100%'}
              height={'100%'}
              src={imageURL}
              alt={''}
            />
            </div>
          ) : (
            <img src={''} alt={''} />
          )}
        </div>
      </section>
    </section>
  );
};

export default DropzoneImageInput;
