const marginTop = {
  "mt0.5": {
    marginTop: "0.5em",
  },
  mt1: {
    marginTop: "1em",
  },
  "mt1.5": {
    marginTop: "1.5em",
  },
  mt2: {
    marginTop: "2em",
  },
  "mt2.5": {
    marginTop: "2.5em",
  },
  mt3: {
    marginTop: "3em",
  },
  'mt3.5': {
    marginTop: "3.5em",
  },
  mt4: {
    marginTop: "4em",
  },
  mt5: {
    marginTop: "5em",
  },
  mt10: {
    marginTop: "10em",
  },
  mt15: {
    marginTop: "15em",
  },
};

export default marginTop;
