import { updateReservation as gqUpdateReservation } from "@data/mutations";
import { stripeSubscribe as gqStripeSubscribe } from "@data/queries";
import { API, graphqlOperation } from "aws-amplify";
import { ReservationService } from "../ReservationContext";
import moment from "moment";
import { makeCurrency } from "@components/Currency";
import { formatPhoneNumber } from "@components/PhoneNumber";


function StringToDate(date) {
  return moment(date.substring(0, date.length - 1)).format("M/D/YYYY");
}

const sortByText = (a, b) => {
  var nameA = a.roomTitle.toUpperCase(); // ignore upper and lowercase
  var nameB = b.roomTitle.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

function properCapital(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default class ReservationListService {
  parseList(reservations) {
    return reservations
      .filter((x) => x.userId != "b11ffb2e-9635-4b07-8bba-6374400b1d97")
      .map(
        ({
          status,
          User,
          id,
          totalAmount,

          checkInDate,
          checkOutDate,
          userId,
          Room,
          friendlyId,
          createdAt,
        }) => {
          // const { name, email } = JSON.parse(checkoutDetails);
          const { firstName, lastName, email, phone } = User;

          // const { startDate, endDate, grandTotal, roomTotal, nights, room } = JSON.parse(reservationDetails);

          return {
            id,
            friendlyId,
            phone: formatPhoneNumber(phone),
            createdAt,

            roomTitle: `${Room.Property.shortDescription} - ${Room.title}`,
            name: `${properCapital(firstName)} ${properCapital(lastName)}`,
            email,
            checkIn: StringToDate(checkInDate),
            checkOut: StringToDate(checkOutDate),
            totalPayment: makeCurrency(totalAmount, false),
            roomTotal: makeCurrency(totalAmount),
            nights: moment(checkOutDate).diff(checkInDate, "days") - 1,
            userId: userId,
            status,
          };
        }
      )
      .sort(sortByText);
  }

  async updateStatus(id, status) {
    const reservationSvc = new ReservationService();
    try {
      const reservation = await reservationSvc.getReservation(id);
      delete reservation.User;
      delete reservation.createdAt;
      delete reservation.updatedAt;
      delete reservation.Room;
      delete reservation.Calendar;



      //submit stripe subscription
      if (status === "APPROVED") {
        let resPayload = {
          reservationId: id,
        };
        try {
          const {
            data: { stripeSubscribe: stripeData },
          } = await API.graphql({
            query: gqStripeSubscribe,
            variables: resPayload,
          });

          const {
            data: { updateReservation: data },
          } = await API.graphql(
            graphqlOperation(gqUpdateReservation, {
              input: {
                ...reservation,
                status,
              },
            })
          );

          return data;

        } catch (e) {
          return e;
        }
      }
      else {
        {
          let resPayload = {
            reservationId: id,
          };
          try {
            const {
              data: { updateReservation: data },
            } = await API.graphql(
              graphqlOperation(gqUpdateReservation, {
                input: {
                  ...reservation,
                  status,
                },
              })
            );
  
            return data;
  
          } catch (e) {
            return e;
          }
        }
      }
      // return data;
    } catch (err) {
      throw err;
    }
  }

  async updateEndDate(id, date) {
    const reservationSvc = new ReservationService();
    try {
      const reservation = await reservationSvc.getReservation(id);
      delete reservation.User;
      delete reservation.createdAt;
      delete reservation.updatedAt;
      delete reservation.Room;
      delete reservation.Calendar;

      const {
        data: { updateReservation: data },
      } = await API.graphql(
        graphqlOperation(gqUpdateReservation, {
          input: {
            ...reservation,
            checkOutDate: date,
          },
        })
      );

      //submit stripe subscription
      // if (status === "APPROVED") {
      //   let resPayload = {
      //     reservationId: id,
      //   };
      //   const {
      //     data: { stripeSubscribe: data },
      //   } = await API.graphql({
      //     query: gqStripeSubscribe,
      //     variables: resPayload,
      //   });
      // }
      return data;
    } catch (err) {
      throw err;
    }
  }
}
