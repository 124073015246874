import { makeStyles } from "@material-ui/core";

export const useReservationIntoStyles = makeStyles((theme) => ({
  detailsLayout: {
    display: "flex",
    flexDirection: 'row',

    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column'
    },

    //  [theme.breakpoints.up("sm")]: {
    //    flexDirection: 'column',
    //  },

    // [theme.breakpoints.up("md")]: {
    //   flexDirection: 'column',
    // },

    //  [theme.breakpoints.up("lg")]: {
    //    left: "87px",
    //  },

     [theme.breakpoints.up("xl")]: {
       flexDirection: 'row'
     },
  },

  itemDetail: {
    // [theme.breakpoints.down("xs")]: {},

     [theme.breakpoints.down("sm")]: {
        marginTop: '1em',
     },

    // [theme.breakpoints.up("md")]: {
    //   zIndex: '9999',
    //   left: "55px",
    // },

    //  [theme.breakpoints.up("lg")]: {
    //    left: "87px",
    //  },

     [theme.breakpoints.up("xl")]: {
       // width: '20%'
     },
  }
}));
