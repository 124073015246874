import React, { useEffect, useMemo, useState } from "react";
// import clsx from 'clsx';
// import { useStyles } from '@components/SideMenu/styles';
import { AlertDialog, Select, Table } from "@components";
import { Backdrop, Button, CircularProgress, Grid } from "@material-ui/core";
import ReservationListService from "./ReservationListService";
import { useHistory } from "react-router-dom";
import { useStyles } from "@config";
import { useReservation } from "../ReservationContext";
import ReservationUserDoc from "../ReservationUserDoc";
import moment from "moment";

const ReservationList = () => {
  const classes = useStyles();
  const [showReservationDoc, setShowReservationDoc] = useState(false);
  const [userId, setUserId] = useState("");
  const [reservationToken, setReservationToken] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  const columns = useMemo(
    () => [
      {
        Header: "Reservation List",
        columns: [
          {
            Header: `id`,
            accessor: "id",
          },
          {
            Header: `Date Booked`,
            accessor: "createdAt",
            Cell: ({ value }) => new Date(value).toLocaleDateString("en-US"),
          },
          {
            Header: `Room`,
            accessor: "roomTitle",
          },
          {
            Header: `Check In`,
            accessor: "checkIn",
          },
          {
            Header: `Check Out`,
            accessor: "checkOut",
          },
          {
            Header: `Customer`,
            accessor: "name",
          },
          {
            Header: `Email`,
            accessor: "email",
          },
          {
            Header: `Phone`,
            accessor: "phone",
          },
          {
            Header: `Total Payment`,
            accessor: "totalPayment",
            Cell: ({ value }) => {
              return <div>{`$${value}`}</div>;
            },
          },
          {
            Header: `Nights`,
            accessor: "nights",
          },
          {
            Header: `Status`,
            accessor: "status",
          },
          // {
          //   Header: `User Documents`,
          //   accessor: "userId",
          //   Cell: ({ value }) => {
          //     return (
          //       <Button
          //         variant="contained"
          //         color="primary"
          //         onClick={() => {
          //           setShowReservationDoc(true);
          //           setUserId(value);
          //         }}
          //       >
          //         {`View Documents`}
          //       </Button>
          //     );
          //   },
          // },
          // {
          //   Header: `Status`,
          //   accessor: "status",
          //   Cell: (props) => {
          //     const [statusSelected, setStatusSelected] = useState(props.value);
          //     return (
          // <Select
          //   name="bedType"
          //   label={"Status"}
          //   disabled={(statusSelected.id === "APPROVED")}
          //   value={statusSelected}
          //   values={[
          //     {
          //       id: "PENDING_APPROVAL",
          //       name: "Pending Approval",
          //     },
          //     {
          //       id: "PENDING_PAYMENT",
          //       name: "Pending Payment",
          //     },
          //     {
          //       id: "APPROVED",
          //       name: "Approved",
          //     },
          //     {
          //       id: "CANCELED",
          //       name: "Canceled",
          //     },
          //   ]}
          //   onChange={async (value) => {
          //     const reservationListService = new ReservationListService();
          //     setStatusSelected(value.target.value);
          //     setIsLoading(true);
          //     await reservationListService.updateStatus(
          //       props.cell.row.original.id,
          //       value.target.value
          //     );

          //     setIsLoading(false);
          //   }}
          // />
          //     );
          //   },
          // },
        ],
      },
    ],
    []
  );
  let history = useHistory();
  const {
    isLoading,
    setIsLoading,
    fetchReservations,
    reservations,
    setReservations,
  } = useReservation();
  const [rawReservations, setRawReservations] = useState([]);
  let testRes = [];
  // const classes = useStyles();

  // useEffect(() => {
  //   getReservationPage();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (reservationToken.length > 0) {
      getReservationPage(reservationToken[reservationToken.length - 1]);
    } else {
      getReservationPage(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationToken]);

  useEffect(() => {
    setIsLoading(true);
    setReservations(rawReservations.flat());
    setIsLoading(false);
  }, [rawReservations]);

  function handlePagination(page) {
    let token = page === 0 ? null : reservationToken[page - 1];

    (async () => {
      setIsLoading(true);
      const reservationListService = new ReservationListService();
      const rawData = await fetchReservations(token);

      const _reservations = reservationListService.parseList(
        rawData.Reservations
      );
      setReservations(_reservations);
      setIsLoading(false);
    })();
  }

  function getReservationPage(token) {
    (async () => {
      // console.log(`Getting data for token ${!token ? "N/A" : token}.`);
      setIsLoading(true);

      const reservationListService = new ReservationListService();

      const rawData = await fetchReservations(token || null);
      const _reservations = reservationListService.parseList(
        rawData.Reservations
      );
      setRawReservations([...rawReservations, _reservations]);

      if (rawData.nextToken !== null) {
        setReservationToken([...reservationToken, rawData.nextToken]);
      } else {
        // console.log(`Done ${reservationToken.length} tokens`);
      }
    })();
  }

  function goTo(values) {
    // console.log("Since goTo", values);
    history.push(`/reservations/${values.id}/info`);
  }

  const data = React.useMemo(() => reservations, [reservations]);

  return (
    <Grid container spacing={1}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12}>
        {pageCount && (
          <Table
            columns={columns}
            // morePageSize={[60, 70, 80]}
            data={reservations.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            )}
            onRowClick={(values) => goTo(values)}
            onAddRow={() => {
              goTo({ id: "new" });
            }}
            morePageSize={[]}
            // customPagination
            // manualPageCount={pageCount}
            // handlePagination={handlePagination}
          />
        )}
      </Grid>

      <AlertDialog
        open={showReservationDoc}
        title={"Documents"}
        handleClose={() => {
          setShowReservationDoc(false);
          setUserId("");
        }}
      >
        <ReservationUserDoc userId={userId} />
      </AlertDialog>
    </Grid>
  );
};

export default ReservationList;
