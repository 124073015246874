const imageUrlBase = process.env.REACT_APP_IMAGES_URL;

export const parseUrlImages = (room) => {
  let allImages = [];
  //heroimage room
  allImages.push(constructImageUrl(room.heroImage));

  //room Images
  room.images.forEach((img) => allImages.push(constructImageUrl(img)));

  //propertyImages
  room.Property.images.forEach((img) => allImages.push(constructImageUrl(img)));

  //heroimage property
  allImages.push(constructImageUrl(room.Property.heroImage));

  return allImages;
  
};

export const constructImageUrl = (image) => {
    return `${imageUrlBase}/${image}`;
  };
