import React, { useEffect, useMemo } from "react";
// import clsx from "clsx";
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import { Table } from "@components";
import { useHistory } from "react-router-dom";
import { useRoom } from "../RoomContext";
import RoomListService from "./RoomListService";
import { sortingBool, sortString, useStyles } from "@config";

const RoomList = () => {
  const classes = useStyles();
  const { rooms, setIsLoading, setRooms, fetchRooms, isLoading } = useRoom();
  const columns = useMemo(
    () => [
      {
        Header: "Room List",
        columns: [
          {
            Header: `id`,
            accessor: "id",
          },
          {
            Header: "Property",
            accessor: "property",
            Cell: ({ value }) => {
              return <div>{value.title}</div>;
            },
            sortType: (a, b) => {
              return sortString(
                a.original.property.title,
                b.original.property.title,
              )
            },
          },
          {
            Header: `Title`,
            accessor: "title",
          },
          {
            Header: `Price`,
            accessor: "price",
            Cell: ({ value }) => {
              return <div>{`$${value}`}</div>;
            },
          },
          {
            Header: "Discount",
            accessor: "discount",
          },
          {
            Header: "Has Bathroom",
            accessor: "hasBathroom",
            Cell: ({ value }) => {
              return <div>{value ? "Yes" : "No"}</div>;
            },
            sortType: (a, b) => {
              return sortingBool(a.original.hasBathroom, b.original.hasBathroom)
            },
          },
         
        ],
      },
    ],
    []
  );
  let history = useHistory();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const roomListService = new RoomListService();
      const rawData = await fetchRooms();
      
      const _rooms = roomListService.parseList(rawData);
      setRooms(_rooms);
      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function goTo(values) {
    history.push(`/rooms/${values.id}`);
  }

  const data = React.useMemo(() => rooms, [rooms]);

  return (
    <Grid container spacing={1}>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={data}
          onRowClick={(values) => goTo(values)}
          onAddRow={() => {
            goTo({ id: "new" });
          }}
          morePageSize={[]}
        />
      </Grid>
    </Grid>
  );
};

export default RoomList;
