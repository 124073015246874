import React from "react";
import clsx from "clsx";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "@config";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

const AmenityArrayForm = ({
  amenitiesValues,
  errors,
  handleChange,
  onDeleteAmenity,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {amenitiesValues &&
            amenitiesValues.map(
              (formValues, idx) =>
                formValues && formValues.delete !== true && (
                  <Accordion
                    key={idx}
                    style={{
                      width: "100%",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<KeyboardArrowDownIcon />}
                      aria-controls="panel-price-content"
                    >
                      <Typography className={""}>
                        {formValues.tooltip}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <section
                            style={{
                              paddingBottom: "1em",
                              paddingLeft: "0.5em",
                            }}
                            onClick={() => onDeleteAmenity(idx)}
                          >
                            <Fab
                              size="small"
                              color="primary"
                              aria-label="save"
                              type="button"
                            >
                              <DeleteOutlineOutlinedIcon />
                            </Fab>
                          </section>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                              <section>
                                <TextField
                                  className={[classes.textField].join(" ")}
                                  error={
                                    errors.amenities &&
                                    errors.amenities[idx] &&
                                    errors.amenities[idx].sortOrder !== undefined
                                  }
                                  name="sortOrder"
                                  type={"number"}
                                  label="Sort Order"
                                  value={formValues.sortOrder}
                                  InputProps={{ inputProps: { min: 0 } }}
                                  helperText={
                                    errors.amenities &&
                                    errors.amenities[idx] &&
                                    (errors.amenities[idx].sortOrder
                                      ? errors.amenities[idx].sortOrder
                                      : "")
                                  }
                                  variant="outlined"
                                  onChange={(e) => handleChange(e, idx)}
                                />
                              </section>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                              <section>
                                <TextField
                                  className={[classes.textField].join(" ")}
                                  error={
                                    errors.amenities &&
                                    errors.amenities[idx] &&
                                    errors.amenities[idx].tooltip !== undefined
                                  }
                                  name="tooltip"
                                  label="Tooltip"
                                  value={formValues.tooltip}
                                  helperText={
                                    errors.amenities &&
                                    errors.amenities[idx] &&
                                    (errors.amenities[idx].tooltip
                                      ? errors.amenities[idx].tooltip
                                      : "")
                                  }
                                  variant="outlined"
                                  onChange={(e) => handleChange(e, idx)}
                                />
                              </section>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                              <section>
                                <TextField
                                  className={[classes.textField].join(" ")}
                                  error={
                                    errors.amenities &&
                                    errors.amenities[idx] &&
                                    errors.amenities[idx].icon !== undefined
                                  }
                                  name="icon"
                                  label="Icon"
                                  value={formValues.icon}
                                  helperText={
                                    errors.amenities &&
                                    errors.amenities[idx] &&
                                    (errors.amenities[idx].icon
                                      ? errors.amenities[idx].icon
                                      : "")
                                  }
                                  variant="outlined"
                                  onChange={(e) => handleChange(e, idx)}
                                />
                              </section>
                            </Grid>

                            {formValues.icon && formValues.icon.length > 0 && (
                              <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                                <section className={clsx(classes.mt1)}>
                                  <i
                                    className={`${formValues.icon} fa-3x`}
                                    aria-hidden="true"
                                  ></i>
                                </section>
                              </Grid>
                            )}

                            <Grid item xs={12} md={6}>
                              <section>
                                <TextField
                                  className={[classes.textField].join(" ")}
                                  error={
                                    errors.amenities &&
                                    errors.amenities[idx] &&
                                    errors.amenities[idx].grouping !== undefined
                                  }
                                  name="grouping"
                                  label="Grouping"
                                  value={formValues.grouping}
                                  helperText={
                                    errors.amenities &&
                                    errors.amenities[idx] &&
                                    (errors.amenities[idx].grouping
                                      ? errors.amenities[idx].grouping
                                      : "")
                                  }
                                  variant="outlined"
                                  onChange={(e) => handleChange(e, idx)}
                                />
                              </section>
                            </Grid>

                            <Grid item xs={12}>
                              <section>
                                <TextField
                                  className={[classes.textField].join(" ")}
                                  error={
                                    errors.amenities &&
                                    errors.amenities[idx] &&
                                    errors.amenities[idx].description !== undefined
                                  }
                                  name="description"
                                  label="Description"
                                  value={formValues.description}
                                  helperText={
                                    errors.amenities &&
                                    errors.amenities[idx] &&
                                    (errors.amenities[idx].grouping
                                      ? errors.amenities[idx].description
                                      : "")
                                  }
                                  variant="outlined"
                                  onChange={(e) => handleChange(e, idx)}
                                />
                              </section>
                            </Grid>
                          
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AmenityArrayForm;
