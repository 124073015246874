import React from "react";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { MainListItems, secondaryListItems } from "./ListItems";
import { useStyles } from "./styles";
import { useLayout } from "@components/Layout";
import { Logo } from "@components/icons";
import { useHistory } from "react-router-dom";
import { AmplifySignOut } from "@aws-amplify/ui-react";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        {"Medbed Admin"}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SideMenu({ children }) {
  let history = useHistory();
  const classes = useStyles();
  const [dataLayout, setDataLayout] = useLayout();
  const handleDrawerOpen = () => {
    setDataLayout((dataLayout) => ({
      ...dataLayout,
      openDrawer: true,
    }));
  };

  const handleDrawerClose = () => {
    setDataLayout((dataLayout) => ({
      ...dataLayout,
      openDrawer: false,
    }));
  };

  const onClickListItem = (goTo) => {
    history.push(`/${goTo}`);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          dataLayout.openDrawer && classes.appBarShift
        )}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              dataLayout.openDrawer && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {dataLayout.drawerTitle}
          </Typography>
          <AmplifySignOut />
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBar>

      <Drawer
        variant={"permanent"}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !dataLayout.openDrawer && classes.drawerPaperClose
          ),
        }}
        open={dataLayout.openDrawer}
      >
        <div className={classes.toolbarIcon}>
          <Logo />

          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems onClickList={onClickListItem} />
        </List>
        <Divider />
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
