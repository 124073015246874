import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0.5),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  table: {
    width: '100%',
    borderSpacing: 0,
  },

  tr: {
  },

  TableCellRow: {
    cursor: 'pointer',
    '&:hover': {
      transition: 'background-color 0.2s ease',
      backgroundColor: '#ddd',
    },
    fontWeight: 'bold',
  },

  TableEvenCellRow: {
    backgroundColor: '#eee',
  },
  TableOddCellRow: {
    backgroundColor: 'white',
  },

  TableCell: {
    '&:hover': {},
  },

  tableHeaderCell: {
  },  
  
  pagination: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),

    [theme.breakpoints.up("xs")]: {
      flexDirection: 'column',
    },

    [theme.breakpoints.up("sm")]: {
      flexDirection: 'column',
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: 'row',
    },

    [theme.breakpoints.up("lg")]: {
      flexDirection: 'row',
    },

    // [theme.breakpoints.up("xl")]: {
    //   flexDirection: 'row',
    // },
  },

  paginationNumering: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up("xs")]: {
      marginTop: '1em',
      flexDirection: 'column',
    },

    [theme.breakpoints.up("sm")]: {
      flexDirection: 'row',
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: 'row',
    },

    [theme.breakpoints.up("lg")]: {
      flexDirection: 'row',
    },
  
    // [theme.breakpoints.up("xl")]: {
    //   flexDirection: 'row',
    // },
  },

  paginationInformation: {
    display: 'flex',   
  },

  paginationInput: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up("xs")]: {
      flexDirection: 'column',
    },

    [theme.breakpoints.up("sm")]: {
      flexDirection: 'row',
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: 'row',
    },

    [theme.breakpoints.up("lg")]: {
      flexDirection: 'row',
    },

    // [theme.breakpoints.up("xl")]: {
    //   flexDirection: 'row',
    // },
  }
}));


export default useStyles
