import React, { useEffect, useState } from "react";
import { listContents, getContent } from "@data/queries";
import { updateContent } from "@data/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { useLayout } from "@components/Layout";
import { Paper, List, ListItem, ListItemText, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const ContentList = () => {
  const history = useHistory();
  const [contentList, setContentList] = useState();
  const [, setDataLayout] = useLayout();

  const getContent = async () => {
    try {
      const {
        data: {
          listContents: { items },
        },
      } = await API.graphql(graphqlOperation(listContents));

      return items;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const selectContent = async (id) => {
    history.push(`/content/${id}`);
    //   let content = contentList.filter(content=>content.id === id)[0];
    //   delete content.createdAt;
    //   delete content.updatedAt;

    //  console.log(content)
  };

  const fetchContent = async () => {
    let _content = await getContent();
    setContentList(_content);
  };

  useEffect(() => {
    setDataLayout((datalayout) => ({
      ...datalayout,
      drawerTitle: "Content",
    }));

    fetchContent();
  }, []);

  // return <div>{content && <pre>{JSON.stringify(content, null, 2)}</pre>}</div>;
  //
  return (
    <Paper>
      <List>
        {contentList &&
          contentList.map((content, idx) => {
            return (
              <>
              <ListItem button>
                <ListItemText
                  primary={content.contentTitle}
                  secondary={`${content.text.substring(0, 25)}...`}
                  onClick={() => selectContent(content.id)}
                  style={{ cursor: "pointer" }}
                />
              </ListItem>
              {(contentList.length !== idx + 1) ? <Divider/> : null}
              </>
            );
          })}
      </List>
    </Paper>
  );
};

export default ContentList;
